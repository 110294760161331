import {
    Alert,
    AlertTitle,
    AppBar,
    Button,
    CircularProgress,
    Collapse,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    TextField,
    Toolbar,
    Typography,
  } from "@mui/material";
  import { green, grey } from "@mui/material/colors";
  import { Box } from "@mui/system";
  import { useFormik } from "formik";
  import React, { useEffect, useState } from "react";
  import SponsorComponent from "./SponsorComponent";
  import * as Yup from "yup";
  import Visibility from "@mui/icons-material/Visibility";
  import VisibilityOff from "@mui/icons-material/VisibilityOff";
  import FooterComponent from "./FooterComponent";
  import CloseIcon from '@mui/icons-material/Close'
 
  
  import { useNavigate } from "react-router-dom";
  import {
    sendEmail
  } from "../services/LevelServices";
const ForgotPasswordComponent = () => {
    const [loading, setLoading] = useState(false);
    const[alert,setAlert]=useState(false)
    const [error,setError]=useState("")
    const navigate = useNavigate()
    const formik= useFormik({
        initialValues:{
            email:""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
              
              .required("Email es requerido"),
            
          }),
          onSubmit:async(values)=>{
            setLoading(true)
            const resp= await sendEmail(values.email)
            setLoading(false)
            if(resp.res==="1"){
                navigate("/newPassword")
            }
            else{
                setError(resp.msg)
            }
          }
    })
  return (
    <Box
    sx={{
      height: "100vh",
      display: 'flex',
      flexDirection:'column',
    }}
  >
    <Box sx={{ flexGrow: 0 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          color: grey[500],
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "center",
            overflowY: "visible",
            maxHeight: "80px",
          }}
        >
          <Box
            sx={{
              borderBottomLeftRadius: "22px",
              borderBottomRightRadius: "22px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",

              alignItems: "baseline",
              justifyContent: "center",
              width: { xs: "70%", md: "30%" },
              paddingBottom: "30px",
              zIndex: "10",
              backgroundColor: "white",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/Logo_2.png`}
              style={{ marginTop: "80px" }}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
    <Box
      sx={{
        width: {
          md: "80%",
          xs: "100%",
          lg: "60%",
        },
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginInline: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: {
            xs: "90%",
            md: "50%",
          },
          paddingTop: "80px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h5" sx={{color:"#12C185", fontWeight:"Bold"}}>Reestablecer Contraseña</Typography>

        
        <Box
          sx={{
            width: "15%",
            marginLeft: "12px",
            borderRadius: "50%",
            border: "3px solid #86AF35",
            overflow: "hidden",
            display:'flex',
            mb: 6
          }}
        >
            
          <img
            src={`${process.env.PUBLIC_URL}/mascota 1.png`}
            className="form__image"
          />
        </Box>
        
      </Box>
      
      <form onSubmit={formik.handleSubmit} className="form__register">
      {alert && <Collapse in={alert}> <Alert severity='error' 
              sx={{ mt: 1, mb: 4 }}
              action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >

             
      <AlertTitle>Error</AlertTitle>
       <strong>{error}</strong>
    </Alert></Collapse>}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: {
              md: "40%",
              xs: "69%",
            },
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "15px",
              color: "black",
              marginBottom: "9px",

              textAlign: "left",
            }}
            htmlFor="email"
          >
            Correo electrónico
          </InputLabel>
          <TextField
            id="email"
            name="email"
            variant="filled"
            size="small"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && (
            <FormHelperText>{formik.errors.email}</FormHelperText>
          )}
          
        </Box>
        {!loading ? (
          <>
            {" "}
            <Button
              variant="contained"
              type="submit"
              className='button__animation'
              sx={{
                width: "216px",
                height: "46px",
                backgroundColor: "#12C185",

                textTransform: "capitalize",
                marginTop: "60px",
                mb:4,
                borderRadius: "36px",
                fontWeight: "900",
                fontSize: "16px",
                lineHeight: "24px",
                "&:hover": {
                  background: green[300],
                },
              }}
            >
              enviar
            </Button>
            
          </>
        ) : (
          <CircularProgress sx={{mt:'12px',mb:12}}/>
        )}
      </form>

      <SponsorComponent />
    </Box>
    <FooterComponent />
  </Box>
  )
}

export default ForgotPasswordComponent