import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectLevel } from '../features/Target/targetSlice';
const CapsuleItemComponent = ({state, title,type,color,typeText,id,capIdd,actualLevel}) => {
    
    const level = useSelector(selectLevel)
    const [BgState,setBgState]= useState('')
    const history= useNavigate()
    const getBgColor=()=>{
        switch(state){
            case 'completado' : setBgState('#12C185')
                                break
            case 'en proceso' : setBgState(color)
                                break
            case 'bloqueado': setBgState('#C4C4C4')
                                break
        }
    } 
  
    const levelNum=()=>{
        switch(actualLevel){
            case 'Básico': return 1
            case 'Intermedio': return 2
            case 'Experto': return 3
        }
    }
    useEffect(() => {
      getBgColor();
     
    }, []);
    const nextPage=()=>{
         if(state!=='bloqueado'){
            history(`/capsule/${capIdd}/activity/${id}`)
        }
        if(type==='Evaluaciones'){
            history(`/test/${localStorage.getItem('target')}/${levelNum()}/final`)
        }
    }
  return <Box sx={{
      border: `2px solid ${color}`,
      borderRadius: '23px',
      display: 'flex',
      flexDirection:'column',
      padding: '32px 28px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '70%',
      cursor:`${state ==='bloqueado'? 'text': 'pointer'}`,
      marginTop: '20px',
      marginBottom:'20px'
  }} className='capsule' 
  onClick={nextPage}>
      <Box sx={{
          display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '100%'

      }}>
          <Typography variant="p" className='capsuleItem__title' sx={{
              fontSize:{
                  xs:'0.9em',

                  xl:'1.2em'
              },
              textAlign:'left'
          }}>{title}</Typography>
         
          <Box sx={{
              backgroundColor: `${BgState}`,
              borderRadius:'21.5px',
              maxWidth: '100px',
              minWidth: '20%',
              
height: '38px',

display: 'flex',
alignItems: 'center',
justifyContent: 'center'
                
          }}>
              
              {state==="completado"?(
                  <DoneOutlinedIcon sx={{color: 'white'}}/>

              ):(state==="en proceso"?(
                    <DirectionsRunOutlinedIcon sx={{color: 'white'}}/>
              ):(
                  <LockOutlinedIcon sx={{color:'white'}}/>
              ))}


          </Box>
         
      </Box>
      <Box sx={{
              display : 'flex',
              width: '100%',
              alignItems: 'center',
                justifyContent: 'stretch'
          }}>
              {type==="Juego"|| type==="Emociones"?(
                  <VideogameAssetOutlinedIcon sx={{color:'#555555',mr:'8px'}}></VideogameAssetOutlinedIcon>
              ):
              type==="Video"|| type==="Audio"?(
                  <OndemandVideoOutlinedIcon sx={{color:'#555555', width:{xs: '20px'},mr:'8px'}}></OndemandVideoOutlinedIcon>
              ):
            type=='Cartilla'? <Icon src={`${process.env.PUBLIC_URL}/reading.png`}/>:
            <Icon src={`${process.env.PUBLIC_URL}/challenge.png`} />
                
            }
              
                <Typography className="capsuleItem__type" variant='p' sx={{
                    fontSize:{
                        xs: '0.8em',
                        xl:'1.1em'
                    }
                }}>{typeText}</Typography>
          </Box>
  </Box>;
};

export default CapsuleItemComponent;

const Icon = styled.img`
width:4%;
margin-right: 8px;
@media (max-width: 768px){
    width: 6%;
}
@media(min-width: 1536px){
    width: 15px;
}
`