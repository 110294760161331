import {
  Alert,
  AlertTitle,
  AppBar,
  Button,
  CircularProgress,
  Collapse,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Toolbar,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import SponsorComponent from "./SponsorComponent";
import * as Yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FooterComponent from "./FooterComponent";
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from "react-redux";
import {
  setActivity,
  setCapsule,
  setLevel,
  setTarget,
} from "../features/Target/targetSlice";
import { useNavigate } from "react-router-dom";
import {
  getActivity,
  getCapsule,
  getLevel,
  getTarget,
  Login,
} from "../services/LevelServices";
const LoginComponent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const[alert,setAlert]=useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const setUsertarget = async (id) => {
    let target = await getTarget(id);
    dispatch(setTarget(target[0]));
  };
  const setUserCapsule = async (id) => {
    let capsule = await getCapsule(id);
    dispatch(setCapsule(capsule[0]));
  };
  const setUserActivity = async (id) => {
    let activity = await getActivity(id);

    dispatch(setActivity(activity[0]));
  };
  const setUserLevel = async (id) => {
    let level = await getLevel(id);

    dispatch(setLevel(level[0]));
  };
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };
  useEffect(()=>{
    if(localStorage.getItem('user')){
      navigate('/level')
    }
  },[])
  const initialUser = {
    email: "",
    password: "",
  };
  const formik = useFormik({
    initialValues: initialUser,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        
        .required("Email o nickname es requerido"),
      password: Yup.string().required("Contraseña es requerida"),
    }),
    onSubmit: async (values) => {
      
     
      setLoading(true);
      const resp = await Login(values)

      if(!resp.error){
      localStorage.setItem('user',resp[0].user_id)
      localStorage.setItem('target',resp[0].target_id)
      localStorage.setItem('level',resp[0].level_id)
      localStorage.setItem('capsule',resp[0].capsule_id)
      localStorage.setItem('activity',resp[0].activity_id)
      setUsertarget(resp[0].target_id);
      setUserCapsule(resp[0].capsule_id);
        localStorage.setItem('nick',resp[0].nickname)
      setUserLevel(resp[0].level_id);
      setUserActivity(resp[0].activity_id);
      setAlert(false)
      //setTimeout(navigate,5000,"/level",{replace:true})
      navigate('/level',{replace:true})
      }
      else
      {
        setLoading(false)
        setAlert(true)
      }
  
    },
  });
  return (
    <Box
      sx={{
        height: "100vh",
        display: 'flex',
        flexDirection:'column',
      }}
    >
      <Box sx={{ flexGrow: 0 }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "white",
            color: grey[500],
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "center",
              overflowY: "visible",
              maxHeight: "80px",
            }}
          >
            <Box
              sx={{
                borderBottomLeftRadius: "22px",
                borderBottomRightRadius: "22px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                display: "flex",

                alignItems: "baseline",
                justifyContent: "center",
                width: { xs: "70%", md: "30%" },
                paddingBottom: "30px",
                zIndex: "10",
                backgroundColor: "white",
              }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/Logo_2.png`}
                style={{ marginTop: "80px" }}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          width: {
            md: "80%",
            xs: "100%",
            lg: "60%",
          },
          flexGrow: 1,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginInline: "auto",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: {
              xs: "90%",
              md: "50%",
            },
            paddingTop: "80px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/Frame-7.png')`,
              backgroundRepeat: "no-repeat",
              width: "50%",
              height: "67px",
              paddingTop: "10px",
              paddingLeft: "8px",
              paddingRight: "16px",
              //border: '1px solid red',
              backgroundSize: "contain",
            }}
          ></Box>

          <Box
            sx={{
              width: "20%",
              marginLeft: "12px",
              borderRadius: "50%",
              border: "3px solid #86AF35",
              overflow: "hidden",
              display:'flex'
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/mascota 1.png`}
              className="form__image"
            />
          </Box>
        </Box>
        <form onSubmit={formik.handleSubmit} className="form__register">
        {alert && <Collapse in={alert}> <Alert severity='error' 
                sx={{ mt: 1, mb: 4 }}
                action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >

               
        <AlertTitle>Usuario incorrecto</AlertTitle>
         <strong>Intente nuevamente</strong>
      </Alert></Collapse>}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: {
                md: "40%",
                xs: "69%",
              },
            }}
          >
            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "15px",
                color: "black",
                marginBottom: "9px",

                textAlign: "left",
              }}
              htmlFor="email"
            >
              Correo electrónico
            </InputLabel>
            <TextField
              id="email"
              name="email"
              variant="filled"
              size="small"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <FormHelperText>{formik.errors.email}</FormHelperText>
            )}
            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "15px",
                color: "black",
                marginBottom: "9px",
                marginTop: "19px",
                textAlign: "left",
              }}
              htmlFor="password"
            >
              Contraseña
            </InputLabel>
            <TextField
              id="password"
              variant="filled"
              size="small"
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handlePassword}
                      onMouseDown={handlePassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility sx={{ color: "#12C185" }} />
                      ) : (
                        <VisibilityOff sx={{ color: "#12C185" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.password && formik.errors.password && (
              <FormHelperText>{formik.errors.password}</FormHelperText>
            )}
          </Box>
          {!loading ? (
            <>
              {" "}
              <Button
                variant="contained"
                type="submit"
                className='button__animation'
                sx={{
                  width: "216px",
                  height: "46px",
                  backgroundColor: "#12C185",

                  textTransform: "capitalize",
                  marginTop: "20px",
                  mb:4,
                  borderRadius: "36px",
                  fontWeight: "900",
                  fontSize: "16px",
                  lineHeight: "24px",
                  "&:hover": {
                    background: green[300],
                  },
                }}
              >
                ingresar
              </Button>
              <Link className="password__link" sx={{mb:12}} onClick={()=>navigate("/forgotPassword")}>He olvidado mi contraseña</Link>
            </>
          ) : (
            <CircularProgress sx={{mt:'12px',mb:12}}/>
          )}
        </form>

        <SponsorComponent />
      </Box>
      <FooterComponent />
    </Box>
  );
};

export default LoginComponent;
