import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from "@mui/material";
import styled from "styled-components";
import { green } from "@mui/material/colors";
import draftToHtml from 'draftjs-to-html';
import { clearEditorContent } from 'draftjs-utils'
const QuestionsComponent = ({src}) => {
    
    const[answer,setAnswer]=useState(true)
    const[index,setIndex]=useState(0)
    const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
   const onEditorChange = (editorState) => {
    
    setEditorState(editorState);
  };
  const answerQuestion=()=>{
      //uploadQuestion
      
     
      if(index<src.questions.length){
        
        setIndex(index+1) 
        
      }
      else{
        setAnswer(false)
      }
      setEditorState(clearEditorContent(editorState))
  }
  return (
    <Box
        sx={{
          
          width: {
            xs: "100%",
            md: "100%",
          },

          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "12px",
          flexGrow:0
        }}
      >
          <Box
          sx={{
            width: "80%",

            backgroundColor: "#87BC1F",
            borderRadius: "20px",
            textAlign: "center",

            color: "white",
            display: "flex",
            alignItems: "center",
            marginTop: "24px",
            justifyContent: "center",
            flexDirection: "column",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Title>Pregunta</Title>
          <Instructions>{index < src.questions.length?src.questions[index].question:"Gracias por responder las preguntas"}</Instructions>
        </Box>
        <Box sx={{
            backgroundColor:'white',
            maxWidth:'90%',
            marginTop:'12px',
            height:'416px'
        }}>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="editor__margin"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorChange}
          />
          </Box>
          <Button
            variant="contained"
            disabled={!answer}
            sx={{
              width: "120px",
              height: "40px",
              backgroundColor: "#12C185",
              marginTop: "15px",
              marginBottom: "15px",
              borderRadius: "36px",
              "&:hover": {
                background: green[300],
              },
            }}
            onClick={answerQuestion}
          >
            Responder
          </Button>
      </Box>
  )
}

export default QuestionsComponent

const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
`;
const Instructions = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
`;