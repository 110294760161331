import { Alert, Button, Input, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import FileUpload from "react-mui-fileuploader"
import axios from 'axios'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import styled from 'styled-components'
import SaveIcon from '@mui/icons-material/Save'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors'
const FileComponent = ({activity}) => {
    const[file,setFile]=useState()
    const[loading,setLoading]=useState(false)
    const[alert,setAlert]=useState(false)
    const[wobble,setWobble]=useState(0)
    function handleChange(event) {
      setWobble(1)
        setFile(event.target.files[0])
      }
      
      async function  handleSubmit () {
        setWobble(1)
        setLoading(true)
        const url = 'https://www.mqmc.org/cemse_service/upload_file.php';
        const formData = new FormData();
        formData.append('fileToUpload', file);
        formData.append('activity_id',activity)
        formData.append('user_id', localStorage.getItem('user'));
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        await axios.post(url, formData, config).then((response) => {
          console.log(response.data);
          fileUploaded()
        });
        // setTimeout(fileUploaded, 4000);
    
      }
      const fileUploaded=()=>{
            setLoading(false)
            setAlert(true)
            
      }
  return (
    <Box sx={{
        
        width:{
            xs:'100%',
            md:'100%'
        },
        height:'200px',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }}>
        <Box sx={{
            display:'flex',
            alignItems:'center'
        }}>
        {file&&<AttachFileIcon/>}
        <Typography variant='h6' sx={{
            margin: '12px'
        }}>
            {file? file.name: "No ha cargado ningun archivo"}
        </Typography>
        </Box>
        <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file"  type="file" onChange={handleChange} style={{display:'none'}}/>
        {!loading && !file &&<Button variant="contained" component="span" type='submit' className='button__animated' wobble={wobble} startIcon={<FileUploadIcon/>}onClick={handleChange} onAnimationEnd={()=>setWobble(0)}
        sx={{
          width: "216px",
          height: "46px",
          backgroundColor: "#12C185",  
          borderRadius: "36px",
          fontWeight: "900",
          fontSize: "16px",
          lineHeight: "24px",
          "&:hover": {
            background: green[300],
          },
        }}
        >
          Subir
        </Button>
        }
      </label>
         {!loading && file && !alert&&<Button variant="contained" component="span" type='submit' className='button__animated' wobble={wobble} startIcon={<SaveIcon/>}onClick={handleSubmit} onAnimationEnd={()=>setWobble(0)}
         sx={{
          width: "216px",
          height: "46px",
          backgroundColor: "#12C185",
          borderRadius: "36px",
          fontWeight: "900",
          fontSize: "16px",
          lineHeight: "24px",
          "&:hover": {
            background: green[300],
          },
         }}
         >
          Guardar
        </Button>
        }
        {loading &&
        <CircularProgress sx={{color:'#12C185'}}/>
        }
        {
          alert &&  <Alert severity="success">Su archivo fue guardado correctamente!</Alert>
        }
    </Box>
  )
}

export default FileComponent

const UploadForm=styled.form`
display:flex;
flex-direction:column;
align-items: center;
justify-content:center;

`