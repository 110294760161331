import { AppBar, Avatar, Toolbar, Button } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import FooterComponent from './FooterComponent';
import MascotComponent from './MascotComponent';
import SponsorComponent from './SponsorComponent';
import LoginModalComponent from './LoginModalComponent';
import { useNavigate } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import GoogleLoginComponent from './GoogleLoginComponent';
const PrincipalComponent = () => {
  const [open, setOpen]= useState(false)
  const handleClose =()=>{
    setOpen(false)
  }
  const openModal= ()=>{
    
    setOpen(true)
  }
  const navigate= useNavigate()
  return <div>
          <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" sx={{
        backgroundColor: 'white',
        color: grey[500],
        
    }}>
      <Toolbar sx={{
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'visible',
          maxHeight: '80px'
      }}>
          <Box sx={{
          borderBottomLeftRadius: '22px',
          borderBottomRightRadius: '22px',
          boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
          display: 'flex',
        
          alignItems: 'baseline',
          justifyContent: 'space-between',
          width: {xs:'75%',md:'30%'},
          paddingBottom: '30px',
          zIndex: '10',
          backgroundColor:'white',
          paddingLeft: {md:'12px'},
          paddingRight:'12px'
      }}>
            <img src={`${process.env.PUBLIC_URL}/Logo_2.png`} style={{marginTop: '80px'}}/>
            <img src={`${process.env.PUBLIC_URL}/LOGO_principal.png`} style={{marginTop: '80px',height:'80px'}}/>
      </Box>
      
        
        
      </Toolbar>
    </AppBar>
  </Box>
  <Box
      sx={{
          width:{
              md: '80%',
              xs:'100%',
              lg:'60%'
          },
          
          backgroundColor: 'white',
          display: 'flex',
          flexDirection:'column',
         alignItems: 'center',
         marginLeft: 'auto',
         marginRight: 'auto',
        position: 'relative'

      }}>
          <Box sx={{
              width: '100%',
              backgroundColor:'#F9EFB7',
              borderBottomLeftRadius: '30px',
              borderBottomRightRadius:'30px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
                paddingTop:{
                    xs: '30px'
                }
          }}>
              <img src={`${process.env.PUBLIC_URL}/PORTADA-PRINCIPAL.png`}style={{width: '80%'}}/>
              <Box sx={{
                display:'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: { lg:'30%', xs:'60%',md:'30%'},
                paddingTop: '30px',
                paddingBottom: '30px'

            }}>
                <p className='login__text'><span className='login__pageName'>ME QUIERO</span>, <span className='login__welcome'>ME CUIDO</span> es un programa gratuito
para aprender a conocernos y reconocernos.</p>

<p className='login__welcome' style={{marginTop: '16px'}}>¡BIENVENID@S!</p>

            </Box>
            
          </Box>
          <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '70%',
                padding:'30px',
                flexDirection:{
                    xs:'column',
                    lg:'row'
                },
                
            }}>
                <Button variant= 'contained' 
                    sx={{
                        width: "200px",
                        height: "46px",
                        backgroundColor: "white",
                        border: '2px solid #12C185',
                        color: '#12C185',
                        textTransform: 'Capitalize',
                        marginTop: "8px",
                        borderRadius: "36px",
                        fontWeight: "900",
                        fontSize: "14px",
                        lineHeight: "24px",
                        "&:hover": {
                          background: green[300],
                        },
                        
                    
                }} onClick={()=> navigate('/login')}>ingresar</Button>
                <Button variant='contained'
                sx={{
                    width: "200px",
                    height: "46px",
                    backgroundColor: "#12C185", 
                    textTransform: 'none',
                    marginTop: "8px",
                    borderRadius: "36px",
                    fontWeight: "900",
                    fontSize: "14px",
                    lineHeight: "24px",
                    "&:hover": {
                      background: green[300],
                    },
               
            }}
              onClick={()=>navigate('/register')}  >Crear una cuenta</Button>
              {/* <GoogleLoginComponent/> */}
                <MascotComponent click={openModal}/>
            </Box>
            <a className="login__link" onClick={()=>navigate('/terms')}>Términos y condiciones</a>
            <SponsorComponent/>
      </Box>
            <FooterComponent/>
            <LoginModalComponent open={open} handleClose={handleClose}/>
  </div>;
};

export default PrincipalComponent;
