import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const BadgeComponent = ({state}) => {
    const [color, setColor]=useState('#12C185')
    useEffect(() => {
      switch(state){
          case 'completado': setColor('#12C185')
                                break
        case 'en proceso': setColor('#FFC803')
                            break
        case 'bloqueado': setColor('#C4C4C4')
                            break
      }
    
      
    }, [state]);
    
    return (
      
    <Box sx={{
        backgroundColor:`${color}`,
        position:'absolute',
         padding: '9px 12px',
        left: {xs:'65%',xl:'55%'},
 
         borderBottomLeftRadius: '8px',
         borderBottomRightRadius: '8px'
    }}>
      <Typography variant='p' className='badge__title' sx={{fontSize:{
        xl:'18px'
      }}}>
        {state}
      </Typography>
        
    </Box>
  );
};

export default BadgeComponent;
