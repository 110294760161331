import { Alert, AlertTitle, Button, CircularProgress, Collapse, FormControl, FormHelperText, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from "formik";
  import React, { useState } from "react";
  import FooterComponent from "./FooterComponent";

  import SponsorComponent from "./SponsorComponent";
  import * as Yup from "yup";
import { sendMessage } from "../services/LevelServices";
import { useNavigate } from "react-router-dom";
import HeaderComponent from './HeaderComponent'
import { green } from '@mui/material/colors';

const PersonalFormComponent = () => {
    const [loading, setLoading] = useState(false);
  const[alert,setAlert]=useState(false)
  const [error,setError]=useState(false)
  const[success,setSuccess]=useState(false)
  const navigate= useNavigate()
    const initialReport = {
        option: "problema personal",
        description: "",
      };
      const formik = useFormik({
        initialValues: initialReport,
        validationSchema: Yup.object().shape({
          
          description: Yup.string()
            .matches(
              /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s .]*$/,
              "Descripción solo puede contener letras y espacios"
            )
            .required("Rellene la descripción por favor"),
        }),
        onSubmit: async (values) => {
          //console.log(values);
          setLoading(true)
          const resp= await sendMessage(values)
          if(!resp.error){
            setSuccess(true)
            setAlert(true)
            setLoading(false)
            setError(false)
          }
         else{
          setSuccess(false)
          setAlert(true)
          setLoading(false)
          setError(true)
         }
        },
      });
  return (
    <Box sx={{height:'100vh', display:'flex',flexDirection:'column'}}>
          <HeaderComponent />
          <Box
            sx={{
              width: {
                md: "80%",
                xs: "100%",
                lg: "60%",
              },
              flexGrow:1,
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
            
              marginRight: "auto",
              marginLeft: "auto",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant='p' className='report__title' sx={{marginTop:'30px'}}>
            Quiero hablar de algo que me está pasando
                </Typography>
            <form onSubmit={formik.handleSubmit} className='form__report'>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: {xs:"70%", xl:'70%'},
                  alignItems: "center",
                  justifyContent: "center",
                  paddingLeft: "auto",
                  paddingRight: "auto",
                  textAlign:'left',
                  alignSelf:'center',
                  
                }}
              >
                
                
                  
                <FormControl>
                  <Typography variant='p' className='report__title' sx={{marginTop:'30px', marginBottom:'20px', textAlign:'center', fontWeight:'500'}}>
                  La información que envíes será compartida solamente con un especialista.
                  </Typography>
                  <TextField
              id="description"
              name='description'
              
              multiline
              rows={10}
              value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{
                      '&.Mui-focused': {
                        borderColor: green[500],
                      }
                    }}
            />
            {formik.touched.description && formik.errors.description && (
                    <FormHelperText error>{formik.errors.description}</FormHelperText>
                  )}
                </FormControl>
    
              </Box>
              {!loading && !alert && <Button
                variant="contained"
                sx={{
                  width: "216px",
                  height: "46px",
                  backgroundColor: "#12C185",
    
                  textTransform: "none",
                  marginTop: "30px",
                  marginBottom: {xs:'50px',md:'30px',xl:"90px"},
                  borderRadius: "36px",
                  fontWeight: "900",
                  fontSize: "16px",
                  lineHeight: "24px",
                  "&:hover": {
                    background: green[300],
                  },
                  
                }}
                type="submit"
              >
                Enviar
              </Button>}
            </form>
            {
              loading && <CircularProgress sx={{mb:6}}/>
            }
            {alert && success && (
          <Collapse in={alert}>
            {" "}
            <Alert
              severity="success"
              sx={{ mt: 1, mb: 4 }}
              action={
                <Button color="inherit" size="small" onClick={()=>navigate(-1)}>
                  Continuar
                </Button>
              }
            >
              <AlertTitle>Pregunta Enviada</AlertTitle>
              <strong>Gracias por enviar su pregunta</strong>
            </Alert>
          </Collapse>
        )}
            {alert && error && (
          <Collapse in={alert}>
            {" "}
            <Alert
              severity="error"
              sx={{ mt: 1, mb: 4 }}
              action={
                <Button color="inherit" size="small" onClick={()=>setAlert(false)}>
                  Reintentar
                </Button>
              }
            >
              <AlertTitle>Ocurrió un error</AlertTitle>
              <strong>Por favor vuelva a intentarlo</strong>
            </Alert>
          </Collapse>
        )}
            <SponsorComponent />
          </Box>
          <FooterComponent />
        </Box>
  )
}

export default PersonalFormComponent