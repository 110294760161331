import { Alert, AlertTitle, Box, Button, Collapse, IconButton } from '@mui/material'
import { green, orange, red } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
const TrueFalseComponent = ({src}) => {
    const[severity,setSeverity]=useState("error")
    const[feedback,setFeedback]=useState("Esa no es la respuesta correcta")
    const [alert,setAlert]=useState(false)
    const verifyAnswer=(answer)=>{
        if(answer===src.answer){
            setSeverity("success")
            setFeedback("¡Elegiste la respuesta correcta!")
        }
        setAlert(true)

    }
    useEffect(() => {
    setSeverity("error")
    setFeedback("Esa no es la respuesta correcta")  
    setAlert(false)
    }, [src])
    
  return (
    <Box sx={{
        
        width:'100%',
        height:'fit-content',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }}>
       
        <Box sx={{
          width:{xs:'100%',md:'60%'},
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            paddingTop:'12px',
            paddingLeft: {xs: '6px',md:'12px'},
            paddingRight: {xs: '6px',md:'12px'},
            
        }}>
        
              
        
        <Button  variant="contained" sx={{
                   width: '150px',
                   height:'50px',
                    backgroundColor: '#12C185',
                    
                    borderRadius: '11px',
                   '&:hover':{
                       background: green[300]
                   },
                   mb:2
                }}
                onClick={()=>{verifyAnswer(true)}}
                >{src.buttons[0]}</Button>
        <Button  variant="contained"  sx={{
                   width: '150px',
                   height:'50px',
                    backgroundColor: '#D73C27',
                   
                    borderRadius: '11px',
                   '&:hover':{
                       background: red[300]
                   },
                   mb:2
                }}
                onClick={()=>{verifyAnswer(false)}}
                >{src.buttons[1]}</Button> </Box>
                {alert && <Collapse in={alert}> <Alert severity={severity} 
                sx={{ mt: 1, mb: 4 }}
                action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >

               
        <AlertTitle>Enviado</AlertTitle>
         <strong>{feedback}</strong>
      </Alert></Collapse>}
      
    </Box>
  )
}

export default TrueFalseComponent