import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';

import { Avatar } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
const HeaderComponent = () => {
  const navigate= useNavigate()
  return (
    <Box sx={{ flexGrow: 0 }}>
    <AppBar position="static" sx={{
        backgroundColor: 'white',
        color: grey[500],  
    }}>
      <Toolbar sx={{
          display: 'flex',
          justifyContent: 'space-around'
      }}>
          <img src={`${process.env.PUBLIC_URL}/Logo_2.png`} alt="logo" style={{height: '60px'}}/>
          <img src={`${process.env.PUBLIC_URL}/LOGO_principal.png`} style={{height: '60px'}} alt="logo principal"/>
      <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <Avatar  sx={{width: '39', height: '39', marginRight: '14px'}}  />
        <a className='username__link' onClick={()=>navigate('/profile')}>{localStorage.getItem('nick')}</a>
      </Box>

      </Toolbar>
    </AppBar>
  </Box>
  )
};

export default HeaderComponent;
