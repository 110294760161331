import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import targetReducer from '../features/Target/targetSlice'
export const store = configureStore({
  reducer: {
    
    target: targetReducer
  }
  
},applyMiddleware(thunk));
