import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './App.css';
import CapsuleContentComponent from './components/CapsuleContentComponent';
import CapsuleSelectionComponent from './components/CapsuleSelectionComponent';
import {ErrorBoundary} from 'react-error-boundary';
import LevelSelectionComponent from './components/LevelSelectionComponent';
import PrincipalComponent from './components/PrincipalComponent';
import ProfileComponent from './components/ProfileComponent';
import WellcomeComponent from './components/WellcomeComponent';
import { selectActivity, setActivity, setCapsule, setLevel, setTarget } from './features/Target/targetSlice';
import {HashRouter as Router, Route,Routes} from 'react-router-dom'
import ActivityComponent from './components/ActivityComponent';
import VictoryComponent from './components/VictoryComponent';
import TroubleFormComponent from './components/TroubleFormComponent';
import QuestionFormComponent from './components/QuestionFormComponent';
import ReportFormComponent from './components/ReportFormComponent';
import { getActivity, getCapsule, getLevel, getTarget } from './services/LevelServices';
import ErrorComponent from './components/ErrorComponent';
import RegisterComponent from './components/RegisterComponent';
import LoginComponent from './components/LoginComponent';
import TermsConditionsComponent from './components/TermsConditionsComponent';
import TestComponent from './components/TestComponent';
import LibraryComponent from './components/LibraryComponent';
import PersonalFormComponent from './components/PersonalFormComponent';
import {gapi} from 'gapi-script'
import ReactGA from 'react-ga';
import ForgotPasswordComponent from './components/ForgotPasswordComponent';
import NewPasswordComponent from './components/NewPasswordComponent';
  const TRACKING_ID = "G-D78Y51VK7B"; // OUR_TRACKING_ID
  const clientID='328729368229-2jlqiqcqh91n1go1o8g3le1lsek1mupv.apps.googleusercontent.com'

  ReactGA.initialize(TRACKING_ID);
function App() {
  const [user,setUser]=useState()
  const dispatch= useDispatch()
  const setUserCapsule=async(id)=>{
    let capsule = await getCapsule(id)
    dispatch(setCapsule(capsule[0]))
  }
  const setUsertarget = async (id) => {
    let target = await getTarget(id);
    dispatch(setTarget(target[0]));
  };
  const setUserLevel=async(id)=>{
    let level= await getLevel(id)
    
    dispatch(setLevel(level[0]))
  }
  
  useEffect(() => {
    
    const setUserActivity=async(id)=>{
      let activity= await getActivity(id)
      
      dispatch(setActivity(activity[0]))
    }
    if(localStorage.getItem("user")){
  
        setUsertarget(localStorage.getItem('target'))
        setUserLevel(localStorage.getItem('level'))
        setUserActivity(localStorage.getItem('activity'))
        setUserCapsule(localStorage.getItem('capsule'))

    }
   
    ReactGA.pageview(window.location.pathname + window.location.search);
    function start(){
      gapi.client.init({
        clientId:clientID,
        scope:""
      })
    }
    gapi.load('client:auth2',start)
  },[])
  
  return (
    <div className="App">
      {/* <WellcomeComponent/> */}
      
      <Router>
        <Routes>
        <Route  path="/principal" element={<PrincipalComponent/>}>
              
        </Route>
        <Route exact path="/" element={<PrincipalComponent />}>
              
            </Route>
        <Route  path="/level" element={<ErrorBoundary FallbackComponent={ErrorComponent}><LevelSelectionComponent/></ErrorBoundary>}>
            
        </Route>
      
            <Route  path="/level/:id/capsules" element={<ErrorBoundary FallbackComponent={ErrorComponent}><CapsuleSelectionComponent/></ErrorBoundary>}>
                
            </Route>
            <Route  path="/capsule/content/:id" element={<ErrorBoundary FallbackComponent={ErrorComponent}><CapsuleContentComponent/></ErrorBoundary>}>
              

            </Route>
            <Route  path="capsule/:capsuleId/activity/:id" element={<ErrorBoundary FallbackComponent={ErrorComponent}><ActivityComponent/></ErrorBoundary>}>
                
            </Route>
            <Route  path="/profile" element={<ErrorBoundary FallbackComponent={ErrorComponent}><ProfileComponent/></ErrorBoundary>}> 
                
            </Route>
            <Route  path="/register" element={<RegisterComponent/>}> 
                
            </Route>
            
            <Route exact path="/victory" element={<VictoryComponent/>}> 
                
            </Route>
            <Route exact path='/troubleForm' element={<TroubleFormComponent/>}></Route>
            <Route exact path='/questionForm' element={<QuestionFormComponent/>}></Route>
            <Route exact path='/reportForm' element={<ReportFormComponent/>}></Route>
            <Route exact path='/login' element={<LoginComponent/>}></Route>
            <Route exact path='/terms' element={<TermsConditionsComponent/>}></Route>
            <Route exact path='/wellcome' element={<WellcomeComponent/>}></Route>
            <Route exact path='/library' element={<LibraryComponent/>}></Route>
            <Route exact path='/personalForm' element={<PersonalFormComponent/>}></Route>
            <Route exact path='/test/:target_id/:level_id/:type' element={<TestComponent/>}></Route>
            <Route exact path="/forgotPassword" element={<ForgotPasswordComponent/>}/>
            <Route exact path="/newPassword" element={<NewPasswordComponent/>}/>
        </Routes>

      </Router>
    </div>
  );
}

export default App;
