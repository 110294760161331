import { Box } from '@mui/material'
import React from 'react'
import ReactPlayer from 'react-player'

const AudioComponent = ({src}) => {
  return (
    <Box sx={{
        
        width:{
            xs:'100%',
            md:'100%'
        },
        height:'200px',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }}>
        <div className='player-wrapper'> <ReactPlayer url={src}   playing
          height={'60px'} width={'100%'} controls={true} /></div> 
    </Box>
  )
}

export default AudioComponent