import { Button } from '@mui/material';
import { green } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { selectTarget } from '../features/Target/targetSlice';
import BannerComponent from './BannerComponent';

import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import MascotComponent from './MascotComponent';
import SponsorComponent from './SponsorComponent';

const VictoryComponent = () => {
    const target = useSelector(selectTarget);
    const { id } = useParams();
    const history = useNavigate();
    const demo=(ages)=>{
      if(ages==='6 a 8 años' || ages==='9 a 12 años') return 'niñas y niños'
      else if(ages==='13 a 19 años') return 'jóvenes'
      else return ''
    }

  return (
      <div>
          <HeaderComponent/>
    <Box
    sx={{
        width:{
            md: '80%',
            xs:'100%',
            lg:'60%'
        },
        
        backgroundColor: 'white',
        display: 'flex',
        flexDirection:'column',
       
      marginLeft: 'auto',
      marginRight: 'auto',
      position: 'relative'

    }}>
        <BannerComponent demo={demo(target.name)} name={target.name} color={target.color} img={target.img}/>
        <Box sx={{
            width: {md:'40%',xs:'80%'},
            backgroundImage:`url('${process.env.PUBLIC_URL}/tatu-fin 1.png')`,
            height: '260px',
            alignSelf:'center',
            backgroundRepeat:'no-repeat',
            backgroundSize:'contain',
            backgroundPosition:'center',
            margin: '25px'

        }}>

        </Box>
        <Box sx={{
            width: {md:'50%', xs:'90%'},
            padding: '30px',
            alignSelf:'center',
        }}>
            <p className='victory__congratulation'>¡FELICIDADES!</p>

            <p className='victory__details'>Haz completado el programa</p>
        </Box>
        <Button  variant="contained"  sx={{
            alignSelf:'center',
                   width: '200px',
                   height:'60px',
                    backgroundColor: '#12C185',
                    fontSize: '19px',
                    fontWeight: 'bold',
                    mb:12,
                    borderRadius: '36px',
                   '&:hover':{
                       background: green[300]
                   }
                }} onClick={()=>history('/level')}>Continuar</Button>
                {/* <MascotComponent/> */}
                <SponsorComponent/>
        </Box>
    <FooterComponent/>    
    </div>
  )
};

export default VictoryComponent;
