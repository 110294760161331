import React from 'react';
import {Box,Button} from '@mui/material';
import { green } from "@mui/material/colors";
const GameComponent = ({game}) => {
  return (
    <Box sx={{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      justifyContent:'center'
    }}> 
      {game && <iframe width='360px' height='610px'src={game} scrolling="no" className="iframe__border"></iframe>}
   
    </Box>)
  
};

export default GameComponent;
