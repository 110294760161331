import { AppBar, Box, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Toolbar } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from "yup";
import { useFormik } from "formik";
import SponsorComponent from './SponsorComponent'
import FooterComponent from './FooterComponent'
import TermsDialogComponent from './TermsDialogComponent';
const Form1Component = ({formData,setFormData,next}) => {
  const history= useNavigate()

  const formik= useFormik({
    initialValues: formData,
    validationSchema: Yup.object().shape({
      target: Yup.string().required("Debe seleccionar una edad"),
     
    }),
    onSubmit: async (values) => {
      setFormData(values)
     
      next()

    },
  }

  )
  return (       
  <Box
      sx={{
          width:{
              md: '80%',
              xs:'100%',
              lg:'60%'
          },
          flexGrow: 1,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection:'column',
         alignItems: 'center',
        marginInline: 'auto',
        position:'relative',
        height:'100%'

      }}>
          <Box sx={{
              display: 'flex',
              width:{
                  xs: '90%',
                  md:'50%'
              },
              paddingTop: '80px',
              alignItems: 'center',
              justifyContent: 'center'
          }}>
              {/* <Box sx={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/Frame-3.png)`,
                  backgroundRepeat: 'no-repeat',
                  width: '50%',
                  height: '67px',
                  paddingTop: '10px',
                  paddingLeft: '8px',
                  paddingRight: '16px',
                   //border: '1px solid red',
                  backgroundSize: 'contain',
                  
                                }}>
                  

              </Box> */}
              <TermsDialogComponent/>
            <Box sx={{
                width: '20%',
                marginLeft: '12px',
                borderRadius: '50%',
                border: '3px solid #86AF35',
                overflow: 'hidden',
                display:'flex'
            }}>
               <img src={`${process.env.PUBLIC_URL}/mascota 1.png`} className='form__image'/> 
            </Box>
             
          </Box>
          <form onSubmit={formik.handleSubmit}>
          <Box>
            
              <FormControl>
      
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={formik.values.target}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="target"
       
      >
        <FormControlLabel value="1" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}} />} label="6 a 8 años" />
        <FormControlLabel value="2" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="9 a 12 años" />
        <FormControlLabel value="3" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="13 a 19 años" />
        <FormControlLabel value="4" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="Líderes comunitarios" />
        <FormControlLabel value="5" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="Madres/padres de familia" />
        <FormControlLabel value="6" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="Educadores/Docentes" />
      </RadioGroup>
      {formik.touched.target && formik.errors.target &&
      <FormHelperText error>{formik.errors.target}</FormHelperText>
      }
    </FormControl>
    
              </Box>
              <Button variant='contained'
                sx={{
                    width: "216px",
                    height: "46px",
                    backgroundColor: "#12C185",
                    
                    
                    textTransform: 'none',
                    marginTop: "30px",
                    marginBottom: '100px',
                    borderRadius: "36px",
                    fontWeight: "900",
                    fontSize: "16px",
                    lineHeight: "24px",
                    "&:hover": {
                      background: green[300],
                    },
                
            }}
            type='submit'    >Siguiente</Button>
      </form>
      <SponsorComponent/>
      </Box>
     
     )  
};

export default Form1Component;
