import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
import { green } from '@mui/material/colors'
import { Box } from '@mui/system'
import { useFormik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as Yup from "yup";
import { setTarget } from '../features/Target/targetSlice'
import { getTarget, updateTarget } from '../services/LevelServices'
const TargetModalComponent = ({open,handleClose}) => {
    const history= useNavigate()
    const dispatch = useDispatch()
  const formik= useFormik({
    initialValues: {
        target:''
    },
    validationSchema: Yup.object().shape({
      target: Yup.string().required("Debe seleccionar una edad"),
     
    }),
    onSubmit: async (values) => {
      
     
      //service update target
      const resp = await updateTarget(values,localStorage.getItem('user'))
      
      if(!resp.error){
        const target = await getTarget(values.target);
        if (!target.error) {
          dispatch(setTarget(target[0]));
          history('/wellcome')
        }
       
          
      }
      handleClose()

    },
  }

  )
  return (
    <Dialog open={open}
    onClose={handleClose}>
        <DialogTitle>Selecciona tu edad</DialogTitle>
        <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Box>
            
              <FormControl>
      
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        value={formik.values.target}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="target"
       
      >
        <FormControlLabel value="1" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}} />} label="6 a 8 años" />
        <FormControlLabel value="2" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="9 a 12 años" />
        <FormControlLabel value="3" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="13 a 19 años" />
        <FormControlLabel value="4" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="Líderes comunitarios" />
        <FormControlLabel value="5" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="Madres/padres de familia" />
        <FormControlLabel value="6" control={<Radio sx={{ color: '#12C185','&.Mui-checked': {
      color: '#12C185',
    },}}/>} label="Educadores/Docentes" />
      </RadioGroup>
      {formik.touched.target && formik.errors.target &&
      <FormHelperText error>{formik.errors.target}</FormHelperText>
      }
    </FormControl>
    
              </Box>
              <Button variant='contained'
                sx={{
                    width: "216px",
                    height: "46px",
                    backgroundColor: "#12C185",
                    
                    
                    textTransform: 'none',
                    marginTop: "30px",
                    marginBottom: '80px',
                    borderRadius: "36px",
                    fontWeight: "900",
                    fontSize: "16px",
                    lineHeight: "24px",
                    "&:hover": {
                      background: green[300],
                    },
                
            }}
            type='submit'>Enviar</Button>
      </form>
     
     
        </DialogContent>
    </Dialog>
  )
}

export default TargetModalComponent