import { Box, Typography } from "@mui/material";
import React from "react";
import BackLinkComponent from "./BackLinkComponent";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import { useNavigate } from "react-router-dom";
const CapsuleBannerComponent = ({demo, name, title, level, color, img, number, levelId }) => {
  
  const navigate= useNavigate()
  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "100%",

        backgroundColor: `${color}`,
        borderBottomLeftRadius: "30px",
        borderBottomRightRadius: "30px",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",

          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
             <Box
            sx={{
                display: "flex",
                alignItems: "center",
                width: { md: "50%", xs: "33%" },
                cursor:'pointer'
            }}
            onClick={()=>navigate(`/level/${levelId}/capsules`)}>
            <ArrowLeftRoundedIcon sx={{
          color: '#034337',
          fontSize: {xs:'50px',xl:'70px'}
      }}/>
      <Typography variant='p' className='back__link' sx={{fontSize:{xs:'0.9em',xl:'1.5em'}}}>
        Ir Atrás
      </Typography>
            </Box> 
       
        <Typography
          className="capsule__ageRange"
          variant="p"
          sx={{
            fontSize: { xs: "0.8em", xl: "1.3em" },
            marginRight: { xs: "10px" },
          }}
        >
          {demo} {name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: " flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: { md: "50%", lg: "50%", xs: "100%" },
            padding: {xs:'0',md:"15px",xl:'30px'},
            justifyContent:{xl:'center'},
            display: "flex",
            '& img':{
              width:'100%'
            }
          }}
        >
          <img src={img} className="capsule__bannerImg" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            marginInline: "16px",
            width: { md: "50%", xs: "60%" },
            alignSelf: "center",
            paddingBottom: "12px",
          }}
        >
          {level && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",

                paddingRight: "15px",
              }}
            >
              <Typography
                variant="p"
                className="capsule__bannerLevel"
                sx={{
                  fontSize: { xl: "2.5em" },
                }}
              >
                {level}
              </Typography>
            
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              color: "#12C185",
              fontWeight: "900",
              fontSize: {xs:"20px",xl:'28px'},
              lineHeight: "24px",
              borderRadius: "40px",
              paddingTop: "10px",
              paddingBottom: "10px",
              paddingRight: "12px",
              paddingLeft: "12px",
              marginBottom: "14px",
              marginTop:{xl: '12px'}
            }}
          >
            <p>Cápsula {number}</p>
          </Box>
          <Typography variant='p' className='capsule__bannerTitle' sx={{
              fontSize:{xl:'2.5em'},
              marginTop:{xl: '12px'}
          }}>
                {title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CapsuleBannerComponent;
