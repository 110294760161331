import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
const ComicComponent = ({image}) => {
  console.log(image)
  return <div>
    <Zoom>
       <img src={image} className='comic__img'/>
    </Zoom>
  </div>;
};

export default ComicComponent;
