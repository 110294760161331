import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   target:{ 
    id:0,
    name:'',
    color: '',
    img: ''
    },
    level:{
      id:0,
      name:'',
      points:0,
      target_id:0
    },
    capsule:{
      id:0,
      name:'',
      num: 0,
      image:'',
      level_id:0
    },
    activity:{
      id:0,
      name:'',
      alias:'',
      num:0,
      type:'',
      typeText:'',
      points:0,
      capsule_id:0
    }
  };

  export const targetSlice=(createSlice({
    name: 'target',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
     
     setTarget: (state, action) => {
         
        state.target = action.payload;
      },
      setLevel:(state,action)=>{
        state.level = action.payload;
      },
      setActivity: (state,action)=>{
        state.activity = action.payload;
      },
      setCapsule:(state,action)=>{
        state.capsule = action.payload;
      }
    }}))
    export const { setTarget,setLevel,setActivity,setCapsule } = targetSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTarget = (state) => state.target.target;
export const selectLevel =(state)=> state.target.level;
export const selectActivity= (state)=> state.target.activity;
export const selectCapsule=(state)=> state.target.capsule;
export default targetSlice.reducer