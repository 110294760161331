import { Button } from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectTarget, setActivity, setCapsule, setLevel } from "../features/Target/targetSlice";
import { getActivity, getCapsule, getLevel, insertActivity } from "../services/LevelServices";
import BannerComponent from "./BannerComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import SponsorComponent from "./SponsorComponent";

const WellcomeComponent = () => {
  const history = useNavigate()
  const target = useSelector(selectTarget)
  const demo=(ages)=>{
    if(ages==='6 a 8 años' || ages==='9 a 12 años') return 'niñas y niños'
    else if(ages==='13 a 19 años') return 'jóvenes de '
    else return ''
  }
  const dispatch= useDispatch()
  //8 3 361 63 68 
 
  const setActualCapsule=  async (id)=>{
    const capsule = await getCapsule(id)
    if(!capsule.error){
      dispatch(setCapsule(capsule[0]))
      localStorage.setItem('capsule',id)
    }
  }
  const setActualLevel=  async (id)=>{
    const level = await getLevel(id)
    if(!level.error){
      dispatch(setLevel(level[0]))
      localStorage.setItem('level',id)
    }
  }
  const setActualActivity=  async (id)=>{
    const activity = await getActivity(id)
    if(!activity.error){
      dispatch(setActivity(activity[0]))
      localStorage.setItem('activity',id)
    }
  }
  const startFromScratch= async()=>{
    let activity 
    switch(target.id){
    case '1': {
            activity=8
            
            break;
          } 
    case '2':  {
      activity=3
            break;}
    case '3': {activity=361
            break;}
    case '4': {activity=73
            break;}
    case '5': {activity=63
            break;}
    case '6': {activity= 68
                  break;}
  }
  const newUser = await insertActivity(activity,localStorage.getItem('user'))
 
  if(!newUser.error){
    setActualActivity(newUser[0].activity_id)
    setActualLevel(newUser[0].level_id)
    setActualCapsule(newUser[0].capsule_id)
  }
  }
 
  return (
    <div>
      <HeaderComponent />
      <Box
        sx={{
          width: {
            md: "80%",
            xs: "100%",
            lg: "60%",
          },

          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",

          marginLeft: "auto",
          marginRight:'auto',
          position: "relative",
        }}
      >
        <BannerComponent demo={demo(target.name)}name={target.name} color={target.color} img={target.img} />
        <Box
          sx={{
            width: "80%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "100px",
            marginLeft: "auto",
          marginRight:'auto',
            paddingTop: "12px",
          }}
        >
          {target.id === '1' && (
            <Box
            
              sx={{
                backgroundImage: `url('/Vector.png')`,
                maxWidth: "450px",
                height: "65px",
                paddingTop: "24px",
                PaddingBottom: "0",
                paddingInline: "8px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
              
            >
              <p>¡Vamos a jugar para conocerte mejor!</p>
            </Box>
          )}
          <Box
            sx={{
              width: "200px",
              height: "234px",
              objectFit: "contain",
            }}
          >
            <img src={`${process.env.PUBLIC_URL}/TATU-BIENVENIDOS 1.png`} />
          </Box>
          {target.id >= '2' && (
            <Box
              sx={{
                width: {
                  xs: "90%",
                  md: "80%",
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p className="welcome__text">
                ¡Hola! Te presento el programa “Me Quiero Me Cuido”, donde
                aprenderás un poco sobre la vida, tu cuerpo, tus sentimientos y
                cómo cuidarlos.
              </p>

              <p className="welcome__text">
                Iniciarás este aprendizaje en el nivel básico, pero si
                consideras que ya conoces algo sobre tus sentimientos y tu
                cuerpo puedes saltar el nivel básico respondiendo el siguiente
                cuestionario.
              </p>

              <p className="welcome__text">Recuerda, no puedes fallar en ninguna pregunta.</p>
              <p className="welcome__text">¡Mucho éxito!</p>
            </Box>
          )}
          {target.id==='1'?(<Button
            variant="contained"
            sx={{
              width: "216px",
              height: "64px",
              backgroundColor: "#12C185",
              marginTop: "8px",
              borderRadius: "36px",
              fontWeight: "900",
              fontSize: "16px",
              lineHeight: "24px",
              "&:hover": {
                background: green[300],
              },
            }}
            onClick={()=>{history('/test/1/1/diagnosis')}}
          >
            ¡Vamos!
          </Button>):(
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              width: '80%',
              padding:'30px',
              flexDirection:{
                  xs:'column',
                  lg:'row'
              },
              
          }}>
              <Button variant= 'contained' 
                  sx={{
                      width: "216px",
                      height: "46px",
                      backgroundColor: "white",
                      border: '2px solid #12C185',
                      color: '#12C185',
                      textTransform: 'none',
                      marginTop: "8px",
                      borderRadius: "36px",
                      fontWeight: "900",
                      fontSize: "12px",
                      lineHeight: "24px",
                      "&:hover": {
                        background: green[300],
                      },
                  
              }} onClick={()=>history(`/test/${target.id}/1/diagnosis`)}>Tomar el cuestionario</Button>
              <Button variant='contained'
              // onClick={()=>{history('/level')}}
              sx={{
                  width: "216px",
                  height: "46px",
                  backgroundColor: "#12C185",
                  
                  
                  textTransform: 'none',
                  marginTop: "8px",
                  borderRadius: "36px",
                  fontWeight: "900",
                  fontSize: "12px",
                  lineHeight: "24px",
                  "&:hover": {
                    background: green[300],
                  },
              
          }}
          onClick={()=>{startFromScratch();history('/level')}}  >¡Vamos desde el principio!</Button>
          </Box>
          )}
        </Box>
        <SponsorComponent />
      </Box>
      <FooterComponent />
    </div>
  );
};

export default WellcomeComponent;
