import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React, { useState } from "react";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import "moment/locale/es";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import SponsorComponent from "./SponsorComponent";
import FooterComponent from "./FooterComponent";
import TermsDialogComponent from "./TermsDialogComponent";
import { useEffect } from "react";
import { getCity, getMunicipality, getSchool } from "../services/LevelServices";
const Form2Component = ({ formData, setFormData, next }) => {
  const [value, setValue] = useState(new Date());
  
  //const [gender, setGender] = useState('Femenino');
  const history = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [municipalities,setMunicipalities]=useState([]);
  const [schools,setSchools]=useState([]);
  const getMunicipalities=async(idCity)=>{
    const resp=await getMunicipality(idCity)
    
    if(resp.length>0){
      setMunicipalities(resp.map(municip=>({id:municip.idMunicipality,label:municip.name})))

    }
  }
  const getSchools=async(idMunicipality)=>{
    const resp=await getSchool(idMunicipality)
    
    if(resp.length>0){
      let filteredSchools=resp.filter((school)=>school.idSchool!=='1000000')
     
      if(formData.target!=='5')
      {
        setSchools(filteredSchools.map((school)=>({id:school.idSchool,label:school.name})))
      }
      else{
        setSchools(resp.map((school)=>({id:school.idSchool,label:school.name})))
      }
      

    }
  }

  const onDepartmentChange=(e)=>{
    formik.handleChange(e)
  
    getMunicipalities(e.target.value)
  }
  const onMunicipalityChange=(e,value)=>{
    
    formik.setFieldValue('municipio',value.id)
    
    
    getSchools(value.id)
  }
  const onSchoolChange=(e,value)=>{
    formik.setFieldValue('unidadEducativa',value.id)
  }
  useEffect(() => {
    const getDepartments=async()=>{
      const resp= await getCity()
      if(resp.length>0){
        setDepartments(resp)

      }
    }
    
  getDepartments()
  getMunicipalities('4')
  }, [])
  
  const formik = useFormik({
    initialValues: formData,
    validationSchema: Yup.object().shape({
      birthDate: Yup.date().required(
        "Debe seleccionar una fecha de nacimiento"
      ),
      gender: Yup.string()
        .matches(
          /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s .]*$/,
          "Género solo puede contener letras y espacios"
        )
        .required("Debe especificar un género"),
      municipio: Yup.string().required("Debe especificar un municipio"),
      departamento:Yup.string().required("Debe seleccionar un departamento"),
      unidadEducativa:Yup.string().when("target",{
        is:(target)=>parseInt(target)!==4,
        then: Yup.string().required('La unidad educativa es necesaria')
      })
    }),
    onSubmit: async (values) => {
      console.log(values)
      setFormData(values);
      next();
    },
  });
  // const handleChange = (event) => {
  //   setGender(event.target.value);
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={"es"}>
      <Box
        sx={{
          width: {
            md: "80%",
            xs: "100%",
            lg: "60%",
          },
          flexGrow: 1,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginInline: "auto",
          position: "relative",
          
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: {
              xs: "90%",
              md: "50%",
            },
            paddingTop: "80px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Box
            sx={{
              backgroundImage: `url('${process.env.PUBLIC_URL}/Frame-2.png')`,
              backgroundRepeat: "no-repeat",
              width: "50%",
              height: "67px",
              paddingTop: "10px",
              paddingLeft: "8px",
              paddingRight: "16px",
              //border: '1px solid red',
              backgroundSize: "contain",
            }}
          ></Box> */}
          <TermsDialogComponent/>

          <Box
            sx={{
              width: "20%",
              marginLeft: "12px",
              borderRadius: "50%",
              border: "3px solid #86AF35",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/mascota 1.png`}
              className="form__image"
            />
          </Box>
        </Box>
        <form className="form__register" onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: {
                md: "50%",
                xs: "69%",
              },
            }}
          >
            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "15px",
                color: "black",
                marginBottom: "9px",
                textAlign: "left",
                marginTop: "19px",
              }}
              htmlFor="birthDate"
            >
              Fecha de Nacimiento
            </InputLabel>
            <MobileDatePicker
              id="birthDate"
              name="birthDate"
              value={formik.values.birthDate}
              sx={{
                backgroundColor: "#12C185",
                color: "white",
                border: "0px none",
              }}
              onChange={(value) => {
                formik.setFieldValue("birthDate", value);
              }}
              onBlur={formik.handleBlur}
              renderInput={(params) => (
                <TextField
                  label="Fecha de nacimiento "
                  {...params}
                  className="date__picker"
                />
              )}
            />
            {formik.touched.birthDate && formik.errors.birthDate && (
              <FormHelperText error>{formik.errors.birthDate}</FormHelperText>
            )}
            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "15px",
                color: "black",
                marginBottom: "9px",
                marginTop: "19px",
                textAlign: "left",
              }}
              htmlFor="genre"
            >
              Género
            </InputLabel>
            <TextField
              id="genre"
              select
              className="select"
              value={formik.values.gender}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="gender"
            >
              <MenuItem value="Femenino">Femenino</MenuItem>
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="No especificado">
                Prefiero no especificar
              </MenuItem>
              <MenuItem value="">Me identifico como:</MenuItem>
            </TextField>
            {formik.values.gender !== "Femenino" &&
              formik.values.gender !== "Masculino" &&
              formik.values.gender !== "No especificado" && (
                <>
                  <InputLabel
                    sx={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "black",
                      marginBottom: "9px",
                      marginTop: "19px",
                      textAlign: "left",
                    }}
                    htmlFor="identity"
                  >
                    Me identifico como{" "}
                  </InputLabel>

                  <TextField
                    id="identity"
                    variant="filled"
                    size="small"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </>
              )}
            {formik.touched.gender && formik.errors.gender && (
              <FormHelperText error>{formik.errors.gender}</FormHelperText>
            )}

            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "15px",
                color: "black",
                marginBottom: "9px",
                marginTop: "19px",
                textAlign: "left",
              }}
              htmlFor="departamento"
            >
              Departamento en el que vives
            </InputLabel>
            <TextField
              id="departamento"
              select
              className="select"
              value={formik.values.departamento}
              onChange={(e)=>onDepartmentChange(e)}
              onBlur={formik.handleBlur}
              name="departamento"
            >
              {departments.length>0 ? departments.map(dep=>(
                <MenuItem key={dep.idCity} value={dep.idCity}>{dep.name}</MenuItem>
              ))
            :
            <MenuItem value="4">Seleccione un departamento</MenuItem>
            }
              
              
            </TextField>
            {formik.touched.departamento && formik.errors.departamento && (
              <FormHelperText error>{formik.errors.departamento}</FormHelperText>
            )}
            <InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "15px",
                color: "black",
                marginBottom: "9px",
                marginTop: "19px",
                textAlign: "left",
              }}
              htmlFor="municipio"
            >
              Municipio en el que vives
            </InputLabel>
            <Autocomplete
            id="municipio"
            name="municipio"
            size="small"
            
            options={municipalities}
            onChange={(e,value)=>onMunicipalityChange(e,value)}
              onBlur={formik.handleBlur}
              renderInput={(params) => <TextField {...params}  sx={{
              textAlign:'center'
            }}/>}
            />
            {formik.touched.municipio && formik.errors.municipio && (
              <FormHelperText error>{formik.errors.municipio}</FormHelperText>
            )}
            {formData.target!=='4'&& <><InputLabel
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "15px",
                color: "black",
                marginBottom: "9px",
                marginTop: "19px",
                textAlign: "left",
              }}
              htmlFor="unidadEducativa"
            >
              Unidad educativa en la que {parseInt(formData.target)<4?'estudias':formData.target==='5'?'estudia su hijo':'trabaja actualmente'}
            </InputLabel>
            <Autocomplete
            id="unidadEducativa"
            name="unidadEducativa"
            size="small"
            
            options={schools}
            onChange={(e,value)=>onSchoolChange(e,value)}
              onBlur={formik.handleBlur}
              renderInput={(params) => <TextField {...params}  sx={{
              textAlign:'center'
            }}/>}
            />
            {formik.touched.unidadEducativa && formik.errors.unidadEducativa && (
              <FormHelperText error>{formik.errors.unidadEducativa}</FormHelperText>
            )}</>}
            
            <p className="form__disclaimer">
              Estos datos no los compartiremos con terceros y solamente son para
              fines estadísticos.
            </p>
          </Box>
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "216px",
              height: "46px",
              backgroundColor: "#12C185",

              textTransform: "none",
              marginTop: "30px",
              marginBottom: "100px",
              borderRadius: "36px",
              fontWeight: "900",
              fontSize: "16px",
              lineHeight: "24px",
              "&:hover": {
                background: green[300],
              },
            }}
           
          >
            Siguiente
          </Button>
        </form>
        <SponsorComponent />
      </Box>
    </LocalizationProvider>
  );
};

export default Form2Component;
