import { Link } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectActivity, selectCapsule, selectLevel } from '../features/Target/targetSlice';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import SponsorComponent from './SponsorComponent';
import {useGoogleLogout} from 'react-google-login'
const clientID='328729368229-2jlqiqcqh91n1go1o8g3le1lsek1mupv.apps.googleusercontent.com'
const ProfileComponent = () => {
    const navigate = useNavigate()
    const level=useSelector(selectLevel)
    const capsule = useSelector(selectCapsule)
    const activity = useSelector(selectActivity)
    const onLogOutSuccess=(res)=>{
        logOut()
        localStorage.removeItem('googleLogin')
    }
    const onLogOutFailure=(res)=>{

    }
    const {signOut}= useGoogleLogout({
        clientId:clientID,
        onLogoutSuccess: onLogOutSuccess,
        onFailure: onLogOutFailure
    })
    const logOut=()=>{
        localStorage.removeItem('user')
        localStorage.removeItem('target')
        localStorage.removeItem('level')
        localStorage.removeItem('capsule')
        localStorage.removeItem('activity')
        localStorage.removeItem('nick')
        navigate('/principal',{replace:true})
    }
  return <Box sx={{display:'flex',flexDirection:'column',height:'100vh', width:'100%'}}>
      <HeaderComponent/>
      <Box
      sx={{
        width:{
            md: '80%',
            xs:'100%',
            lg:'60%'
        },
          
          backgroundColor: 'white',
          display: 'flex',
          flexDirection:'column',
         flexGrow:1,
        marginInline: 'auto',
        position: 'relative',
        paddingBottom: '50px'
      }}>
          <Box sx={{
              display:'flex',
              flexDirection:'column',
              alignItems:'flex-start',
              justifyContent: 'center',
              width: '100%',
              height: '311px',
              backgroundColor:'#FFEEB1',
              borderBottomRightRadius: '30px',
              borderBottomLeftRadius: '30px'
          }}>
              <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent:'center',
                  width: '100%',
                  

              }}>
                    <p className='greeting'>¡Hola!</p>
                    <p className='profile__username'>{localStorage.getItem('nick')}</p>
                    
              </Box>
              
          </Box>
          <Box sx={{
                  width:'100%',
                  display:'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingTop: '50px',
                  paddingBottom:'50px'
              }}>
                 
                    <p className="profile__state">Estado Actual</p>
                    <p className='profile__info'>{level.name}, Cápsula {capsule.num}, Actividad {activity.alias}</p>
                    <Link className="password__link" onClick={localStorage.getItem('googleLogin')?signOut:logOut}>Cerrar Sesión</Link>
              </Box>
         <SponsorComponent/>     
      </Box>
      
      <FooterComponent/>
  </Box>;
};

export default ProfileComponent;
