import { Box, Fab } from '@mui/material';
import React from 'react';

const MascotComponent = ({click}) => {
  return (
    <Fab    aria-label="bisho" sx={{
        width:{md:'118px',xs:'60px'},
        height:{md:'118px',xs:'60px'},
        position:'fixed',
        top:{md:'50%',xs:'55%'},
        left:{md:'75%',xs:'80%'},
        zIndex:1000
      }}
      onClick={click}>
      <Box sx={{
              width: '100%',
              backgroundColor:'white',
              borderRadius: '50%',
              border: '3px solid #86AF35',
              overflow: 'hidden',
              display:'flex',
          }}>
             <img src={`${process.env.PUBLIC_URL}/mascota 1.png`} className='form__image img'/> 
          </Box>
    </Fab>
  );
};

export default MascotComponent;
