import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import GameComponent from "./GameComponent";
import VideoComponent from "./VideoComponent";
import ChallengeComponent from "./ChallengeComponent";
import ComicComponent from "./ComicComponent";
import EmotionCheckComponent from "./EmotionCheckComponent";
import ReactPlayer from "react-player";
import TrueFalseComponent from "./TrueFalseComponent";
import AudioComponent from "./AudioComponent";
import FileComponent from "./FileComponent";
import ForumComponent from "./ForumComponent";
import DocumentComponent from "./DocumentComponent";
import QuestionsComponent from "./QuestionsComponent";
import SuitcasesComponent from "./SuitcasesComponent";
import {
  endActivity,
  getActivity,
  getCapsule,
  getLevel,
} from "../services/LevelServices";
import { useDispatch, useSelector } from "react-redux";
import { setActivity as asignActivity } from "../services/LevelServices";
import {
  selectActivity,
  selectLevel,
  setActivity,
  setCapsule,
  setLevel,
} from "../features/Target/targetSlice";
const ContentComponent = ({ contents, target, capsule, state, activity }) => {
  const [actualContent, setActualContent] = useState(0);
  const dispatch = useDispatch();
  const level = useSelector(selectLevel);
  const [updating, setUpdating] = useState(false);
 useEffect(() => {
   
   setActualContent(0)
 
  }, [contents])
  
  const backgroundColor = () => {
    switch (target.name) {
      case "6 a 8 años":
        return "#FFF3C8";
      case "9 a 12 años":
        return "#FFE790";
      case "13 a 19 años":
        return "#FFC690";
      case "Madres/padres de familia":
        return "#F5FEE2";
      case "Educadores / Docentes":
        return "#E1FFA6";
      case "Líderes comunitarios":
        return "#EAFFFA";
    }
  };
  const InstructionsHierarchy = (instructions) => {
    return instructions.split(";");
  };
  const [wobble, setWobble] = useState(0);
  const history = useNavigate();
  const getLevelName = () => {
   
    switch (level.name) {
      
      case "Básico":
        return "Intermedio";
      case "Intermedio":
        return "Experto";
      case "Experto":
        return "Finished";
    }
  };
  const getLevelNumber = () => {
   
    switch (level.name) {
      
      case "Básico":
        return "1";
      case "Intermedio":
        return "2";
      case "Experto":
        return "3";
    }
  };
  const updateProgress = async (idCapsule, idActivity, idLevel) => {
    localStorage.setItem("capsule", idCapsule);
    localStorage.setItem("level", idLevel);
    localStorage.setItem("activity", idActivity);
    const activity = await getActivity(idActivity);
    const capsule = await getCapsule(idCapsule);
    const level = await getLevel(idLevel);

    if (!level.error && !capsule.error && !activity.error) {
    
      dispatch(setActivity(activity[0]));
      dispatch(setLevel(level[0]));
      dispatch(setCapsule(capsule[0]));
    }
  };
  const nextContent = async () => {
    if (actualContent === contents.length - 1) {
      setUpdating(true);
      if (contents[actualContent].instructions === "Evaluación") {
        getLevelName();
        if (getLevelName() === "Finished") {
          history("/victory");
        }
        if (state) {
         
          history("/level");
        } else {
          const progress = await asignActivity(
            level.target_id,
            localStorage.getItem("user"),
            getLevelName()
          );
          
          if (!progress.error) {
            
            await updateProgress(
              progress[0].capsule_id,
              progress[0].activity_id,
              progress[0].level_id
            );
            history("/level");
          }
        }
      } else {
        
        if (state) {
          setUpdating(false);
         
          history(`/capsule/content/${capsule}`);
        } else {
          
          const newData = await endActivity(
            contents[actualContent].activity_id,
            localStorage.getItem("user"),
            localStorage.getItem("level"),
            localStorage.getItem("capsule")
          );
          if (!newData.error) {
           
            localStorage.setItem("capsule", newData[0].capsule_id);
            localStorage.setItem("level", newData[0].level_id);
            localStorage.setItem("activity", newData[0].activity_id);
            const activity = await getActivity(newData[0].activity_id);
            const capsule = await getCapsule(newData[0].capsule_id);
            const level = await getLevel(newData[0].level_id);
            if (!level.error && !capsule.error && !activity.error) {
              if(parseInt(target.id)>3 && capsule[0].name==='Evaluación'){
                const levelNum=getLevelNumber()
                console.log(`/test/${target.id}/${levelNum}/final`)
                history(`/test/${localStorage.getItem('target')}/${levelNum}/final`)
                history(0)
              }
              dispatch(setActivity(activity[0]));
              dispatch(setLevel(level[0]));
              dispatch(setCapsule(capsule[0]));
              console.log(parseInt(target.id),capsule[0].name)
              
            }
            
          }
          setUpdating(false);
         
          
            history(`/capsule/${localStorage.getItem("capsule")}/activity/${localStorage.getItem("activity")}`);

          
              
            
          
        }
      }
    } else {
      setActualContent(actualContent + 1);
    }
  };
  const hasJsonStructure = (str) => {
    if (typeof str !== "string") return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === "[object Object]" || "[ object Array]";
    } catch (err) {
      return false;
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "95%",
          border: `5px solid ${target.color}`,
          backgroundColor: `${backgroundColor()}`,
          borderRadius: "30px",
          padding: "12px",
        }}
      >
        {contents.length > 0 && contents[actualContent] &&
          contents[actualContent].type === "Juego" &&
          !hasJsonStructure(contents[actualContent].src) && (
            <>
              {contents[actualContent].mp3 !== "" && (
                <div className="player-wrapper">
                  {" "}
                  <ReactPlayer
                    url={contents[actualContent].mp3}
                    playing
                    width="100%"
                    height="100%"
                    controls={true}
                    playsinline={true}
                  />
                </div>
              )}
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              <GameComponent game={contents[actualContent].src} />
            </>
          )}
        {contents.length > 0 && contents[actualContent] && contents[actualContent].type === "Pregunta" && (
          <>
            {contents[actualContent].mp3 !== "" && (
              <div className="player-wrapper">
                {" "}
                <ReactPlayer
                  url={contents[actualContent].mp3}
                  playing
                  width="100%"
                  height="100%"
                  controls={true}
                  playsinline={true}
                />
              </div>
            )}
            {InstructionsHierarchy(contents[actualContent].instructions)
              .length > 1 ? (
              InstructionsHierarchy(contents[actualContent].instructions).map(
                (sentence, index) => (
                  <p
                    key={index}
                    className={`${
                      index === 0
                        ? "activity__instruction__hierarchy"
                        : "activity__instruction"
                    }`}
                  >
                    {sentence}
                  </p>
                )
              )
            ) : (
              <p className="activity__instruction">
                {contents[actualContent].instructions}
              </p>
            )}
            <DocumentComponent src={JSON.parse(contents[actualContent].src)} />
          </>
        )}
        {contents.length > 0 && contents[actualContent] && contents[actualContent].type === "Maletas" && (
          <>
            {contents[actualContent].mp3 !== "" && (
              <div className="player-wrapper">
                {" "}
                <ReactPlayer
                  url={contents[actualContent].mp3}
                  playing
                  width="100%"
                  height="100%"
                  controls={true}
                  playsinline={true}
                />
              </div>
            )}
            {InstructionsHierarchy(contents[actualContent].instructions)
              .length > 1 ? (
              InstructionsHierarchy(contents[actualContent].instructions).map(
                (sentence, index) => (
                  <p
                    key={index}
                    className={`${
                      index === 0
                        ? "activity__instruction__hierarchy"
                        : "activity__instruction"
                    }`}
                  >
                    {sentence}
                  </p>
                )
              )
            ) : (
              <p className="activity__instruction">
                {contents[actualContent].instructions}
              </p>
            )}
            {contents[actualContent].img !== "" && (
              <Box>
                <img
                  src={contents[actualContent].img}
                  style={{
                    width: "240px",
                    height: "240px",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
            <SuitcasesComponent
              situation={JSON.parse(contents[actualContent].src).situation}
            />
          </>
        )}
        {contents.length > 0 && contents[actualContent] && contents[actualContent].type === "Trivia" && (
          <>
            {contents[actualContent].mp3 !== "" && (
              <div className="player-wrapper">
                {" "}
                <ReactPlayer
                  url={contents[actualContent].mp3}
                  playing
                  width="100%"
                  height="100%"
                  controls={true}
                  playsinline={true}
                />
              </div>
            )}
            {InstructionsHierarchy(contents[actualContent].instructions)
              .length > 1 ? (
              InstructionsHierarchy(contents[actualContent].instructions).map(
                (sentence, index) => (
                  <p
                    key={index}
                    className={`${
                      index === 0
                        ? "activity__instruction__hierarchy"
                        : "activity__instruction"
                    }`}
                  >
                    {sentence}
                  </p>
                )
              )
            ) : (
              <p className="activity__instruction">
                {contents[actualContent].instructions}
              </p>
            )}
            <QuestionsComponent src={JSON.parse(contents[actualContent].src)} />
          </>
        )}
        {contents.length > 0 && contents[actualContent] &&
          contents[actualContent].type === "Audio" &&
          !hasJsonStructure(contents[actualContent].src) && (
            <>
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              {contents[actualContent].img !== "" && (
                <Box>
                  <img
                    src={contents[actualContent].img}
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <AudioComponent src={contents[actualContent].src} />
            </>
          )}
        {contents.length > 0 && contents[actualContent] &&
          contents[actualContent].type === "foro" &&
          !hasJsonStructure(contents[actualContent].src) && (
            <>
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              {contents[actualContent].img !== "" && (
                <Box>
                  <img
                    src={contents[actualContent].img}
                    style={{
                      width: "340px",
                      height: "340px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <ForumComponent src={contents[actualContent]} />
            </>
          )}
        {contents.length > 0 && contents[actualContent] &&
          contents[actualContent].type === "Archivo" &&
          !hasJsonStructure(contents[actualContent].src) && (
            <>
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              <FileComponent activity={activity}/>
            </>
          )}
        {contents.length > 0 && contents[actualContent] &&
          contents[actualContent].type === "Video" &&
          contents[actualContent].src &&
          !hasJsonStructure(contents[actualContent].src) && (
            <>
              {contents[actualContent].mp3 !== "" && (
                <div className="player-wrapper">
                  {" "}
                  <ReactPlayer
                    url={contents[actualContent].mp3}
                    playing
                    width="100%"
                    height="100%"
                    controls={true}
                    playsinline={true}
                  />
                </div>
              )}
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              <VideoComponent video={contents[actualContent].src} />
            </>
          )}
        {contents.length > 0 && contents[actualContent] &&
          contents[actualContent].type === "Reto" &&
          contents[actualContent].src && (
            <>
              {contents[actualContent].mp3 !== "" && (
                <div className="player-wrapper">
                  
                  <ReactPlayer
                    url={contents[actualContent].mp3}
                    playing
                    height={"60px"}
                    width={"100%"}
                    controls={true}
                    playsinline={true}
                  />
                </div>
              )}
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              <ChallengeComponent
                src={contents[actualContent].src}
                img={contents[actualContent].img}
              />
            </>
          )}
        {contents.length > 0 && contents[actualContent] &&
          contents[actualContent].type === "Cartilla" &&
          !hasJsonStructure(contents[actualContent].src) && (
            <>
              {contents[actualContent].mp3 !== "" && (
                <div className="player-wrapper">
                  {" "}
                  <ReactPlayer
                    url={contents[actualContent].mp3}
                    playing
                    height={"60px"}
                    width={"100%"}
                    controls={true}
                    playsinline={true}
                  />
                </div>
              )}
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              <ComicComponent image={contents[actualContent].src} />
            </>
          )}

        {contents.length > 0 && contents[actualContent] &&
        contents[actualContent].type === "Emociones" &&
        hasJsonStructure(contents[actualContent].src) ? (
          JSON.parse(contents[actualContent].src).option ? (
            <>
              {contents[actualContent].mp3 !== "" && (
                <div className="player-wrapper">
                  {" "}
                  <ReactPlayer
                    url={contents[actualContent].mp3}
                    playing
                    height={"60px"}
                    width={"100%"}
                    controls={true}
                    playsinline={true}
                  />
                </div>
              )}
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}

              {contents[actualContent].img !== "" && (
                <Box>
                  <img
                    src={contents[actualContent].img}
                    style={{
                      width: "240px",
                      height: "240px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <TrueFalseComponent
                src={JSON.parse(contents[actualContent].src).option}
              />
            </>
          ) : (
            <>
              {contents[actualContent].mp3 !== "" && (
                <div className="player-wrapper">
                  {" "}
                  <ReactPlayer
                    url={contents[actualContent].mp3}
                    playing
                    height={"60px"}
                    width={"100%"}
                    controls={true}
                    playsinline={true}
                  />
                </div>
              )}
              {InstructionsHierarchy(contents[actualContent].instructions)
                .length > 1 ? (
                InstructionsHierarchy(contents[actualContent].instructions).map(
                  (sentence, index) => (
                    <p
                      key={index}
                      className={`${
                        index === 0
                          ? "activity__instruction__hierarchy"
                          : "activity__instruction"
                      }`}
                    >
                      {sentence}
                    </p>
                  )
                )
              ) : (
                <p className="activity__instruction">
                  {contents[actualContent].instructions}
                </p>
              )}
              {contents[actualContent].img !== "" && (
                <Box>
                  <img
                    src={contents[actualContent].img}
                    style={{
                      maxWidth: "240px",
                      maxHeight: "240px",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <EmotionCheckComponent
                src={JSON.parse(contents[actualContent].src)}
              />
            </>
          )
        ) : (
          <></>
        )}
        {/* {contents.length > 0 &&
        contents[actualContent].type === "Emociones" && JSON.parse(contents[actualContent].src)===emojis &&
        } */}
      </Box>
      <Button
        variant="contained"
        className="button__animated button__animation"
        disabled={updating}
        sx={{
          width: "120px",
          height: "40px",
          backgroundColor: "#12C185",
          marginTop: "30px",
          borderRadius: "36px",
          "&:hover": {
            background: green[300],
          },
        }}
        onClick={nextContent}
      >
        {actualContent === contents.length - 1 ? "Listo" : "Siguiente"}
      </Button>
    </Box>
  );
};

export default ContentComponent;
