import { green } from '@mui/material/colors';
import { Box } from '@mui/system';
import React from 'react';
import FacebookSharpIcon from '@mui/icons-material/FacebookSharp';
import InstagramIcon from '@mui/icons-material/Instagram';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';

import { Icon, Link } from '@mui/material';
const FooterComponent = () => {
  return (
      <Box
      sx={{
          width: '100%',
          maxHeight: '82px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: 'white',
          backgroundColor: '#93B73B',
          paddingTop: '16px',
          paddingBottom: '18px',
          fontSize: '10px',
          lineHeight: '12px',
          bottom: '0',
         position:'static',
         zIndex:'1',
        
         
      }}>
          <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '12px'
            }}
          >
                <Link color={'#fff'} sx={{cursor:'pointer'}} href='https://www.tiktok.com/@soytatu1'>
                <Icon>
                    <img src={`${process.env.PUBLIC_URL}/tiktok.svg`} />
                </Icon>
                </Link>
                <Link color={'#fff'} sx={{cursor:'pointer'}} href='https://www.facebook.com/MQMCBolivia'>
                <FacebookSharpIcon sx={{marginLeft: '12px' }} />
                </Link>
                <Link color={'#fff'} sx={{cursor:'pointer'}} href='https://www.instagram.com/mqmcbolivia/'>
                <InstagramIcon sx={{marginLeft: '12px' }}/>
                </Link>
          </Box>
          <p>Me quiero, Me cuido 2022</p>
      </Box>
  );
};

export default FooterComponent;
