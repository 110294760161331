import { Button, Fab, Icon, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import BannerComponent from "./BannerComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import SponsorComponent from "./SponsorComponent";
import DirectionsRunOutlinedIcon from '@mui/icons-material/DirectionsRunOutlined';
import MascotComponent from "./MascotComponent";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ActivityModal from "./ActivityModal";
import { useSelector, useDispatch } from 'react-redux';
import {selectTarget, selectLevel} from '../features/Target/targetSlice'
import { useNavigate } from "react-router-dom";
import { getTargetLevels } from "../services/LevelServices";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import MicNoneIcon from '@mui/icons-material/MicNone';
const LevelSelectionComponent = () => {
  const helperText= 'En esta pantalla tienes que elegir el nivel que te corresponde. Si el botón esta gris significa que todavía no has completado el nivel anterior.'
  const [open, setOpen]= useState(false)
  const[levels, setLevels]= useState([])
  const actualLevel = useSelector(selectLevel)
  
  const handleClose =()=>{
    setOpen(false)
  }

    const state=(id)=>{
      if(actualLevel.id>parseInt(id)) return 'completado'
      else if (actualLevel.id<parseInt(id)) return 'bloqueado'
      else return 'en proceso'
    }
  const target = useSelector(selectTarget);
 
  const history = useNavigate()
    useEffect(() => {
      const resp= async()=>{
        let getLevels= await getTargetLevels(target.id)
        setLevels(getLevels)
        
    
      }
      resp()
     
  },[actualLevel])
  const openModal= ()=>{
   
    setOpen(true)
  }
  const demo=(ages)=>{
    if(ages==='6 a 8 años' || ages==='9 a 12 años') return 'niñas y niños'
    else if(ages==='13 a 19 años') return 'jóvenes'
    else return ''
  }
  return (
    <Box sx={{height: '100%'}}>
      <HeaderComponent />
      <Box
        sx={{
           width:{
            md: '80%',
            xs:'100%',
            lg:'60%',
            xl:'70%'
        },

          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          
          marginLeft: "auto",
          marginRight:'auto',
          position: "relative",
          
        }}
      >
        <BannerComponent demo={demo(target.name)} name={target.name} color={target.color} img={target.img}/>
        <Box sx={{
              display: 'flex',
              width:{
                  xs: '90%',
                  md:'50%'
              },
              paddingTop: '20px',
              alignItems: 'center',
              justifyContent: 'center',
              alignSelf:'center'
          }}>
              <Box sx={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/level_frame.png')`,
                  backgroundRepeat: 'no-repeat',
                  width: {xs:'55%',xl:'30%'},
                  height: '67px',
                  paddingTop: '10px',
                  paddingLeft: '8px',
                  paddingRight: '16px',
                   //border: '1px solid red',
                  backgroundSize: 'contain',
                  
                                }}>
                  

              </Box>
              
            <Box sx={{
                width: {xs:'20%', xl:'10%'},
                marginLeft: '12px',
                borderRadius: '50%',
                border: '3px solid #86AF35',
                overflow: 'hidden',
                display:'flex'
            }}>
               <img src={`${process.env.PUBLIC_URL}/mascota 1.png`} className='form__image'/> 
            </Box>
             
          </Box>
        <Box
          sx={{
            width: "80%",
         
            display: "flex",
            flexDirection:{
              xs:'column',
              md:'row',
              lg:'row',
            },
            justifyContent: "space-evenly",
            alignItems: "center",
            marginBottom: "100px",
            marginRight: "auto",
            marginLeft:'auto',
            paddingTop: "12px",
            
          }}
        >
          {levels.length>0 && levels.length<4 && levels.map((level)=>(
            <Box key={level.id} className='level' sx={{
              width:{xs:'120px',md:'120px',lg:'120px', xl:'200px'},
              height:{xs:'120px',md:'120px',lg:'120px',xl:'200px'},
              display:'flex',
              alignItems:'center',
              flexDirection:'column',
              border:`3px solid ${state(level.id)==='bloqueado'?'#C4C4C4':'#12C185'} `,
              color:`${state(level.id)==='bloqueado'?'#C4C4C4':'#12C185'} `,
              borderRadius:'50%',
              justifyContent:'center',
              marginTop:'30px',
              marginBottom:'100px',
              cursor:`${state(level.id)==='bloqueado'? 'text': 'pointer'}`
          }} onClick={()=>{state(level.id)!=='bloqueado'? history(`/level/${level.id}/capsules`):(history('/level'))}}>
            {state(level.id)==='en proceso'&& <DirectionsRunOutlinedIcon sx={{width: {xs:'36px', xl:'72px'},height:{xs:'45px',xl:'90px'}}}/>}
            {state(level.id)==='bloqueado' && <LockOutlinedIcon sx={{width: {xs:'36px', xl:'72px'},height:{xs:'45px',xl:'90px'}}}/> }
            {state(level.id)==='completado' && <DoneOutlinedIcon sx={{width: {xs:'36px', xl:'72px'},height:{xs:'45px',xl:'90px'}}}/> }
            <Typography variant='p' sx={{fontSize:{xl:'24px'}, fontWeight: {xl:'900'}}}>
              {level.name}
            </Typography>
            

          </Box>
          ))}
         
        </Box>
          <MascotComponent click={openModal}/>
          {(target.id==='6' || target.id==='5' )&& <Fab    aria-label="bisho" sx={{
        backgroundColor: '#12C185',
        position:'fixed',
        color:'white',
        top:{md:'70%',xs:'70%'},
        left:{md:'80%',xs:'80%'},
        zIndex:1000
      }} onClick={()=> history('/library')}
      >
      
             <LocalLibraryIcon/>
          
    </Fab>}
          {parseInt(target.id)>=3 && <Fab  variant='extended'  aria-label="bisho" sx={{
        backgroundColor: '#12C185',
        position:'fixed',
        color:'white',
        top:{md:'80%',xs:'80%'},
        left:{md:'80%',xs:'65%'},
        zIndex:1000
      }} 
      >
          
             <MicNoneIcon/>
            <a href='https://soundcloud.com/mediaimpact/sets/radionovela-calicanto-bolivia' target='_blank' className="floating__links">Calicanto</a>
    </Fab >}
          {parseInt(target.id)>=3 && <Fab  variant='extended'  aria-label="bisho" sx={{
        backgroundColor: '#12C185',
        position:'fixed',
        color:'white',
        top:{md:'90%',xs:'90%'},
        left:{md:'80%',xs:'65%'},
        zIndex:1000
      }} 
      >
          
             <MicNoneIcon/>
            <a href='https://soundcloud.com/mediaimpact/sets/la-caldera-radionovela' target='_blank'className="floating__links">La caldera</a>
    </Fab>}
              <SponsorComponent/>
          
        
      </Box>
      <ActivityModal open={open} handleClose={handleClose} helperText={helperText}/>
      <FooterComponent /> 
    </Box>
  );
};

export default LevelSelectionComponent;
