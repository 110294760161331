import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Button } from "@mui/material";
import styled from "styled-components";
import { green } from "@mui/material/colors";
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import { getComments, postComment } from "../services/LevelServices";
import Pagination from '@mui/material/Pagination';
const ForumComponent = ({ src }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const parse = require('html-react-parser');
  const onEditorChange = (editorState) => {
    
    setEditorState(editorState);
  };
  const [page,setPage]=useState(1)
  const [comment, setComment] = useState(false);
  const [comments, setComments] = useState([]);
  const loadPost = async () => {
    let posts = await getComments(src.id);
    setComments(posts);
  };
  const[wobble,setWobble]=useState(0)
  let user={
      user_id:'1',
      nick:localStorage.getItem('nick'),
      content_id:src.id,
      message:draftToHtml(convertToRaw(editorState.getCurrentContent()))
  }
  const publishComment=async()=>{
      setWobble(1)
      setComment(false)
      let post= await postComment(user)
    
      setComment(false)
  }
  const paginate = (array, pageSize, pageNumber) => {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }
  const getPages = (length)=>{
    let pagesArray=[]
    for(let i=0;i<length/3;i++)
    {pagesArray.push(i+1)}
    return pagesArray
  }
  const handleChange=(event,value)=>{
    setPage(value)
  }
  useEffect(() => {
    
    loadPost();
    
  }, [comment]);
  return (
    <Box>
      <Box
        sx={{
          
          width: {
            xs: "100%",
            md: "100%",
          },

          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "29px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "12px",
          flexGrow:0
        }}
      >
        <Box
          sx={{
            width: "80%",
            height: "56px",
            backgroundColor: "#D73C27",
            borderRadius: "36px",
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "700",
            fontSize: "19px",
            lineHeight: "23px",
            color: "white",
            display: "flex",
            alignItems: "center",
            marginTop: "24px",
            justifyContent: "center",
          }}
        >
          <p>Foro</p>
        </Box>
        <Box
          sx={{
            width: "80%",

            backgroundColor: "#87BC1F",
            borderRadius: "20px",
            textAlign: "center",

            color: "white",
            display: "flex",
            alignItems: "center",
            marginTop: "24px",
            justifyContent: "center",
            flexDirection: "column",
            paddingLeft: "30px",
            paddingRight: "30px",
            paddingTop: "15px",
            paddingBottom: "15px",
          }}
        >
          <Title>Consigna</Title>
          <Instructions>{parse(src.src)}</Instructions>
        </Box>
        {!comment && (
          <Button
            variant="contained"
            className='button__animation'
            sx={{
              width: "120px",
              height: "40px",
              backgroundColor: "#12C185",
              marginTop: "15px",
              marginBottom: "15px",
              borderRadius: "36px",
              "&:hover": {
                background: green[300],
              },
            }}
            onClick={()=>setComment(true)}
          >
            Responder
          </Button>
        )}

        {comments.length > 0 &&
          !comment &&
          <>
           {paginate(comments,3,page).map((comment) => (
            <MessageDiv key={comment.id}>
              <Nick>{comment.nick}</Nick>
              <Date>{comment.created_at}</Date>
              <Message>{parse(comment.msg)}</Message>
            </MessageDiv>
          ))}
          <Box sx={{ width:'100%', display:'flex', alignSelf:'flex-end'}}>
            {/* {getPages(comments.length).map(pageNum=>(
                <PageNumbers key={pageNum} className={`${page===pageNum?'selected__page':''}`}onClick={()=>{setPage((pageNum))}}>
                    {pageNum}
                </PageNumbers>
            ))} */}
            <Pagination count={getPages(comments.length).length} defaultPage={1} onChange={handleChange} />
          </Box>
          </>
          }
          
        {comment && 
            <>
        <Box sx={{
            backgroundColor:'white',
            maxWidth:'90%',
            marginTop:'12px',
            height:'416px'
        }}>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="editor__margin"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorChange}
          />
          </Box>
          <Disclaimer>Recuerda ser respetuosa o respetuoso, 
no usar palabras disonantes 
y mantener el lugar con tolerancia. 
Recuerda que hay personas como tú del otro lado.</Disclaimer>
<Button
            variant="contained"
            className='button__animated button__animation'
            sx={{
              width: "120px",
              height: "40px",
              backgroundColor: "#12C185",
              marginTop: "15px",
              marginBottom: "15px",
              borderRadius: "36px",
              "&:hover": {
                background: green[300],
              },
            }}
            onClick={publishComment}
            onAnimationEnd={() => setWobble(0)}
            wobble={wobble}
          >
            Publicar
          </Button>
          </>
        }
      </Box>

      
    </Box>
  );
};

export default ForumComponent;
const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
`;
const Instructions = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
`;
const MessageDiv = styled.div`
  background: #ffffff;
  border-radius: 24px;
  width: 85%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 24px;
  padding: 12px;
  text-align:left;
`;
const Nick = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #1b1b1b;
`;
const Date = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #1b1b1b;
`;
const Message = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  margin-top: 8px;
  margin-bottom: 8px;
`;
const Disclaimer = styled.p`
    font-weight: 700;
font-size: 15px;
line-height: 18px;
margin-left: 20px;
margin-right:20px;
margin-top: 20px;
`
const PageNumbers=styled.div`
    border: 1px solid #12C185;
    width:40px;
    height:40px;
    font-weight:700;
    text-align:center;
    color: white;
    cursor:pointer;
    display:flex;
    align-items:center;
    justify-content:center;
`