import {
  Alert,
  AlertTitle,
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Toolbar,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import React, { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SponsorComponent from "./SponsorComponent";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getActivity, getCapsule, getLevel, Register, setActivity as assignActivity} from "../services/LevelServices";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCapsule, setLevel, setTarget,setActivity } from "../features/Target/targetSlice";
import { getTarget } from "../services/LevelServices";
import TermsModalComponent from './TermsModalComponent'
import TermsDialogComponent from "./TermsDialogComponent";
const Form4Component = ({ formData, setFormData, next }) => {
  
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
const dispatch = useDispatch();
const updateProgress= async (idCapsule,idActivity,idLevel)=>{
    localStorage.setItem('capsule',idCapsule)
    localStorage.setItem('level',idLevel)
    localStorage.setItem('activity',idActivity)
    const activity= await getActivity(idActivity)
    const capsule= await getCapsule(idCapsule)
    const level= await getLevel(idLevel)
    if(!level.error && !capsule.error && !activity.error){
   
      dispatch(setActivity(activity[0]))
      dispatch(setLevel(level[0]))
      dispatch(setCapsule(capsule[0]))
      
    }
  }
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };
  const reset = () => {
    setAlert(false);
    navigate('/register')
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const [error,setError]=useState('')
  const formik = useFormik({
    initialValues: formData,
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Email es invalido")
        .required("Email es requerido"),
      password: Yup.string().required("Contraseña es requerido"),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir")
        .required("Confirmar contraseña es requerido"),
      terms: Yup.bool().oneOf(
        [true],
        "Debe aceptar los términos y condiciones para continuar"
      ),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      
      setFormData(values);
      // here we register and set the token and target
      const resp = await Register(values);
      console.log(resp)

      if (!resp.error) {
    
        const target = await getTarget(values.target);
        if (!target.error) {
          dispatch(setTarget(target[0]));
        }
        localStorage.setItem("nick", values.nickname);

        localStorage.setItem("user", resp.id);
        localStorage.setItem("target", values.target);
        const progress = await assignActivity(values.target, resp.id, "Básico");
        if (!progress.error) {
          await updateProgress(
            progress[0].capsule_id,
            progress[0].activity_id,
            progress[0].level_id
          );
        }
        setLoading(false);
        navigate("/wellcome");
      } else {
        setError(resp.error)
        setAlert(true);
        setLoading(false)
      }
    },
  });
  return (
    <Box
      sx={{
        width: {
          md: "80%",
          xs: "100%",
          lg: "60%",
        },
        flexGrow: 1,
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginInline: "auto",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: {
            xs: "90%",
            md: "50%",
          },
          paddingTop: "80px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Box
          sx={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/Frame.png')`,
            backgroundRepeat: "no-repeat",
            width: "50%",
            height: "67px",
            paddingTop: "10px",
            paddingLeft: "8px",
            paddingRight: "16px",
            //border: '1px solid red',
            backgroundSize: "contain",
          }}
        ></Box> */}
        <TermsDialogComponent/>

        <Box
          sx={{
            width: "20%",
            marginLeft: "12px",
            borderRadius: "50%",
            border: "3px solid #86AF35",
            overflow: "hidden",
            display:'flex'
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/mascota 1.png`}
            className="form__image"
          />
        </Box>
      </Box>
      <form onSubmit={formik.handleSubmit} className="form__register">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: {
              md: "40%",
              xs: "69%",
            },
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "15px",
              color: "black",
              marginBottom: "9px",

              textAlign: "left",
            }}
            htmlFor="email"
          >
            Correo electrónico
          </InputLabel>
          <TextField
            id="email"
            name="email"
            variant="filled"
            size="small"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email && (
            <FormHelperText error>{formik.errors.email}</FormHelperText>
          )}
          <p className="form__disclaimer">
            Usaremos el correo electrónico para recuperar tu contraseña si la
            olvidas o la pierdes.
          </p>

          <InputLabel
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "15px",
              color: "black",
              marginBottom: "9px",
              marginTop: "19px",
              textAlign: "left",
            }}
            htmlFor="password"
          >
            Contraseña
          </InputLabel>
          <TextField
            id="password"
            variant="filled"
            size="small"
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handlePassword}
                    onMouseDown={handlePassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility sx={{ color: "#12C185" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "#12C185" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.password && formik.errors.password && (
            <FormHelperText error>{formik.errors.password}</FormHelperText>
          )}
          <InputLabel
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "15px",
              color: "black",
              marginBottom: "9px",
              marginTop: "19px",
              textAlign: "left",
            }}
            htmlFor="confirmPassword"
          >
            Repite tu contraseña
          </InputLabel>
          <TextField
            id="confirmPassword"
            name="passwordConfirmation"
            variant="filled"
            size="small"
            type={showPassword ? "text" : "password"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.passwordConfirmation &&
            formik.errors.passwordConfirmation && (
              <FormHelperText error>
                {formik.errors.passwordConfirmation}
              </FormHelperText>
            )}
          <p className="form__disclaimer">
            Tu(s) nombre(s) y apellidos no se mostrarán a terceros en la
            plataforma.
          </p>

          
          <Divider sx={{ border: " 1px solid #86AF35", marginTop: "19px" }} />
          <InputLabel
            sx={{
              fontWeight: "bold",
              fontSize: "12px",
              lineHeight: "15px",
              color: "black",
              marginBottom: "9px",
              marginTop: "19px",
              textAlign: "left",
            }}
          >
            Crea una contraseña que puedas recordar
          </InputLabel>

          {/* <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent:'center',
                  marginTop:'9px',
                  
              }}>
                  <Box sx={{width:'30px',height:'30px',backgroundColor:'#F4F4F4',borderRadius:'4px',marginInline:'6px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <img src='/badge1.png'/>
                  </Box>
                  <Box sx={{width:'30px',height:'30px',backgroundColor:'#F4F4F4',borderRadius:'4px',marginInline:'6px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <img src='/badge2.png'/>
                  </Box>
                  <Box sx={{width:'30px',height:'30px',backgroundColor:'#F4F4F4',borderRadius:'4px', marginInline:'6px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <img src='/badge3.png'/>
                  </Box>
                  <Box sx={{width:'30px',height:'30px',backgroundColor:'#F4F4F4',borderRadius:'4px',marginInline:'6px',display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <img src='/badge4.png'/>
                  </Box>
                </Box>               */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={formik.handleChange}
                  name="terms"
                  onBlur={formik.handleBlur}
                  checked={formik.values.terms}
                  sx={{
                    color: green[400],
                    "&.Mui-checked": {
                      color: "#12C185",
                    },
                  }}
                />
              }
              label={
                <Link
                  onClick={() => setOpen(true)}
                  className="password__link"
                >
                  Términos y Condiciones
                </Link>
              }
            />
            {formik.touched.terms && formik.errors.terms && (
              <FormHelperText error>{formik.errors.terms}</FormHelperText>
            )}
          </FormGroup>
        </Box>
        {/* <p className='form__disclaimer'>Esta contraseña es para la niña o niño.</p> */}
        {!alert && !loading && (
          <Button
            variant="contained"
            type="submit"
            sx={{
              width: "216px",
              height: "46px",
              backgroundColor: "#12C185",

              textTransform: "none",
              marginTop: "30px",
              marginBottom: "100px",
              borderRadius: "36px",
              fontWeight: "900",
              fontSize: "16px",
              lineHeight: "24px",
              "&:hover": {
                background: green[300],
              },
            }}
          >
            Registrar
          </Button>
        )}
        {alert && (
          <Collapse in={alert}>
            {" "}
            <Alert
              severity="error"
              sx={{ mt: 1, mb: 4 }}
              action={
                <Button color="inherit" size="small" onClick={reset}>
                  Reintentar
                </Button>
              }
            >
              <AlertTitle>Ocurrió un error</AlertTitle>
              <strong>{error}</strong>
            </Alert>
          </Collapse>
        )}
      </form>
      {loading && <CircularProgress sx={{ mt: "12px", mb: 6 }} />}
      <SponsorComponent />
      <TermsModalComponent open={open} handleClose={handleClose}/>
    </Box>
  );
};

export default Form4Component;
