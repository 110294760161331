import { Box, Button, Fade, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const ChallengeComponent = ({src, img}) => {
    const [showChallenge,setShowChallenge]=useState(false)
    const[wobble,setWobble]=useState(0)
  return (
      <Box sx={{
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          justifyContent:'center'
      }}>
          <DialogContainer>
          <Box className='box1 sb5' sx={{backgroundColor: 'white'}}>

            <p>Esta actividad debe realizarse en casa</p>
          </Box>
          <ImageContainer>
          <img
              src={`${process.env.PUBLIC_URL}/mascota 1.png`}
              className="form__image"
            />
          </ImageContainer>
          </DialogContainer>
        <Fade in={!showChallenge}> 
        <Button  variant="contained" size='large' className='button__animated' sx={{
                   
                   
                    backgroundColor: '#D73C27',
                    marginTop: '30px',
                    borderRadius: '36px',
                   '&:hover':{
                       background: red[300]
                   },
                   mb:2
                }}
               onClick={()=>{setWobble(1);setShowChallenge(true)}}
               onAnimationEnd={()=>setWobble(0)}
               wobble={wobble}
                >Reto</Button>
            </Fade> 
    {showChallenge && <Box sx={{
        backgroundColor:'#FFEEB1',
        width:{
            xs:'120%',
            md:'90%'
        },
        height:'fit-content',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        paddingTop:'25px',
        paddingBottom:'45px',
    }}>
        <Button  variant="contained"  sx={{
                   width: '40%',
                   height:'50px',
                    backgroundColor: '#D73C27',
                    marginTop: '30px',
                    borderRadius: '36px',
                   '&:hover':{
                       background: red[300]
                   },
                   mb:2
                }}
               
                >Reto</Button>
        <Box sx={{
            backgroundColor:'white',
            width:'80%',
            height:'fit-content',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        paddingLeft: '27px',
        paddingRight: '27px',
        paddingTop: '27px',
        paddingBottom:'45px',
        marginTop:'16px'

        }}>
            {img && <img src={img} style={{width:'240px', height: '240px', objectFit:'contain'}}/>}
            <img src={`${process.env.PUBLIC_URL}/challengeImg.png`}/>
            <Typography sx={{
                fontWeight: '300',
                fontSize: '32px',
                lineHeight: '39px',
                marginTop:'16px'
            }}>
                {src}
            </Typography>
        </Box>
    </Box>}
</Box>
  )
}

export default ChallengeComponent

const ImageContainer=styled.div`
    width:10%;
    margin-left: 12px;
    border-radius: 50%;
    border: 3px solid #86AF35;
    overflow: hidden;
    margin-right:auto;
    display:flex;
`
const DialogContainer= styled.div`
width:100%;
display: flex;
align-items:center;
justify-content:center;

`