import { BadgeUnstyled } from '@mui/base';
import { Badge, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BadgeComponent from './BadgeComponent';
import NumberComponent from './NumberComponent';

const CapsuleComponent = ({state, title, number,color,id ,image}) => {
    
    
    const history = useNavigate()
    
    const nextPage=()=>{
      
      if(state!=='bloqueado'){
        history(`/capsule/content/${id}`)
      }
    }
  return (
      <Box
      className={`${state==='bloqueado'? 'capsule__locked':''} capsule`}
              sx={{
            width:{
              xs:'115%',
              md:'90%'
            },
            height: '242px',
            display: 'flex',
            flexDirection:'column',
            alignItems: 'endline',
            background: '#E5E5E5',
            borderRadius:'31px ',
            position: 'relative',
            marginRight: 'auto',
            marginLeft:'auto',
            marginTop: '21px',
            cursor: `${state==='bloqueado' ? 'text' : 'pointer'}`

        }}
        onClick={nextPage}
      
      >     
      {image !== "" &&
      <Box sx={{
        width:'100%',
        height:'80%',
        objectFit:'contain',
        
        '& img':{
          width:'100%',
          height:'100%',
          borderTopRightRadius:'31px',
          borderTopLeftRadius:'31px'
        }
      }}>
      <img src={image} style={{objectFit:'cover'}}/>  
      </Box>}
            <BadgeComponent state={state}/>
          <Box sx={{
              width:' 100%',
              height: '101px',
              color: 'white',
              backgroundColor: `${color}`,
              borderBottomLeftRadius: '31px',
              borderBottomRightRadius: '31px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              bottom: 0

          }}>
              <NumberComponent type={'capsule'} number={number}/>
              <Typography variant='p' className="capsule__title" sx={{
                fontSize:{
                  xs:'1.2em'
                },
                textAlign:'left'
              }}>{title}</Typography>
              

          </Box>
              
            
      </Box>
  );
};

export default CapsuleComponent;

