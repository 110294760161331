import { AppBar, Box, Toolbar } from '@mui/material'
import React, { useState } from 'react'
import FooterComponent from './FooterComponent'
import Form2Component from './Form2Component'
import Form1Component from './Form1Component'
import Form3Component from './Form3Component'
import Form4Component from './Form4Component'
import { grey } from '@mui/material/colors'
import SponsorComponent from './SponsorComponent'
const RegisterComponent = () => {
    const [step, setStep] = useState(1);
    const nextStep = () => {
        if (step <= 4) {
          setStep(step + 1);
        } else if(step === 5) {
          setInitialUser(
          {
                target:'',
                birthDate:new Date(),
                gender:'Femenino',
                departamento:'4',
                municipio:'',
                unidadEducativa:'',
                name:'',
                lastName:'',
                parentName:'',
                nickname:'',
                email:'',
                password:'',
                confirmPassword:'',
                terms: false
              }
            )
            setStep(1)
        }
      };

      const [initialUser,setInitialUser]=useState({
        target:'',
        birthDate:new Date(),
        gender:'Femenino',
        departamento:'4',
        municipio:'',
        unidadEducativa:'',
        name:'',
        lastName:'',
        parentName:'',
        nickname:'',
        email:'',
        password:'',
        confirmPassword:'',
        terms:false
      })

  return (
    <Box sx={{minHeight: '100vh', display:'flex', flexDirection:'column', width:'100%'}}>
        <Box sx={{ flexGrow: 0 }}>
    <AppBar position="static" sx={{
        backgroundColor: 'white',
        color: grey[500],
        
    }}>
      <Toolbar sx={{
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'visible',
          maxHeight: '80px'
      }}>
          <Box sx={{
          borderBottomLeftRadius: '22px',
          borderBottomRightRadius: '22px',
          boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)',
          display: 'flex',
        
          alignItems: 'baseline',
          justifyContent: 'center',
          width: {xs:'70%',md:'30%'},
          paddingBottom: '30px',
          zIndex: '10',
          backgroundColor:'white'
      }}>
            <img src={`${process.env.PUBLIC_URL}/Logo_2.png`} style={{marginTop: '80px'}}/>

      </Box>
      
        
        
      </Toolbar>
    </AppBar>
  </Box>
  {step===1 && <Form1Component formData={initialUser} setFormData={setInitialUser} next={nextStep}/>}
  {step===2 && <Form2Component formData={initialUser} setFormData={setInitialUser} next={nextStep}/>}
  {step===3 && <Form3Component formData={initialUser} setFormData={setInitialUser} next={nextStep}/>}
  {step===4 && <Form4Component formData={initialUser} setFormData={setInitialUser} next={nextStep}/>}
  {/* <SponsorComponent/> */}
  <FooterComponent/>
    </Box>
  )
}

export default RegisterComponent