import { Button, Dialog, DialogActions, DialogContent, Divider } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

const LoginModalComponent = ({open, handleClose}) => {
    const[page, setPage]=useState(1)
    const nextPage=()=>{
        setPage(page+1)
    }
  return <div>
      <Dialog 
      open={open}
      onClose={handleClose}>
          <DialogContent>
                <Box sx={{
                    width:'100%',
                    display:'flex',
                    alignItems: 'center',
                    justifyContent:'center'
                }}>
                <Box
              sx={{
                backgroundImage: `url('/Dialoghelper.png')`,
                width: '335px',
                height: '163px',
                paddingTop: "24px",
                PaddingBottom: "0",
                paddingRight: "50px",
                paddingLeft:'20px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                flexDirection: 'column',
                textAlign: 'center',
                marginBottom: '12px'
              }}
            >
    {           page===1?   <>
              <p>¡HOLA! Soy <strong>Tatú</strong>, tu guía en esta plataforma donde aprenderemos mucho a conocernos y como cuidarnos</p>
<p><strong>¡Bienvenidos!</strong></p>
</>:<>
<p>Si necesitas <strong>ayuda</strong> puedes dar 
<strong>click o tap</strong> en mi para
que pueda ayudarte, estaré presente
en todas las páginas en un pequeño boton redondo a la derecha.</p>
</>}  
            </Box>
            <Box sx={{
                width: '20%',
                marginLeft: '12px',
                borderRadius: '50%',
                border: '3px solid #86AF35',
                overflow: 'hidden',
                display:'flex'
            }}>
               <img src={`${process.env.PUBLIC_URL}/mascota 1.png`} className='form__image'/> 
            </Box>
                </Box>
        <Divider sx={{border:'1px solid #89B237'}}/>
        </DialogContent>
        <DialogActions>
            
        {page===1? <Button onClick={nextPage} variant='contained' sx={{
            backgroundColor:'#89B237',
            textTransform:'capitalize',
            marginRight: '21px'
        }}>Siguiente</Button>:<Button sx={{
            backgroundColor:'#89B237',
            textTransform:'capitalize',
            marginRight: '21px'
        }} onClick={()=>{setPage(page-1); handleClose()}} variant='contained'>¡Entendido!</Button>}  
          
         
        </DialogActions>
      </Dialog>
  </div>;
};

export default LoginModalComponent;
