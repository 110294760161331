import { AppBar, Box, Button, Divider, FormHelperText, InputLabel, TextField, Toolbar } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import React from 'react';
import FooterComponent from './FooterComponent';
import SponsorComponent from './SponsorComponent';
import * as Yup from "yup";
import { useFormik, yupToFormErrors } from "formik";
import { useNavigate } from 'react-router-dom';
import TermsDialogComponent from './TermsDialogComponent';
const Form3Component = ({formData,setFormData,next}) => {
    const history= useNavigate()

    const formik= useFormik({
      initialValues: formData,
      validationSchema: Yup.object().shape({
       
        name: Yup.string().matches(
          /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s .]*$/,
          "Nombre solo puede contener letras y espacios"
        ).required('Debe especificar un nombre'),
        lastName: Yup.string().matches(
          /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s .]*$/,
          "Apellido solo puede contener letras y espacios"
        ).required('Apellido es requerido'),
        parentName: Yup.string().matches(
          /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s .]*$/,
          "Nombre del padre solo puede contener letras y espacios"
        ).when("target",{
          is:(target)=>parseInt(target)<4,
          then: Yup.string().required('El nombre del tutor es necesario')
        }
        ),
        nickname: Yup.string().matches(
          /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s .]*$/,
          "Nick solo puede contener letras y espacios"
        ).required('Debe especificar un Nick')
       
      }),
      onSubmit: async (values) => {
        
       
        setFormData(values)
        next()
      
      },
    }
  
    )
  return(
  <Box
      sx={{
          width:{
              md: '80%',
              xs:'100%',
              lg:'60%'
          },
         flexGrow: 1,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection:'column',
         alignItems: 'center',
        marginInline: 'auto',
        position: 'relative'

      }}>
          <Box sx={{
              display: 'flex',
              width:{
                  xs: '90%',
                  md:'50%'
              },
              
              paddingTop: '80px',
              alignItems: 'center',
              justifyContent: 'center'
          }}>
              {/* <Box sx={{
                  backgroundImage: `url('${process.env.PUBLIC_URL}/Frame-1.png')`,
                  backgroundRepeat: 'no-repeat',
                  width: '50%',
                  height: '67px',
                  paddingTop: '10px',
                  paddingLeft: '8px',
                  paddingRight: '16px',
                   //border: '1px solid red',
                  backgroundSize: 'contain',
                  
                                }}>
                  

              </Box> */}
             <TermsDialogComponent/> 
            <Box sx={{
                width: '20%',
                marginLeft: '12px',
                borderRadius: '50%',
                border: '3px solid #86AF35',
                overflow: 'hidden',
                display:'flex'
            }}>
               <img src={`${process.env.PUBLIC_URL}/mascota 1.png`} className='form__image'/> 
            </Box>
             
          </Box>
          <form onSubmit={formik.handleSubmit} className='form__register'>

          
          <Box sx={{display: 'flex',
        flexDirection: 'column',width:{
            md:'40%',
            xs:'69%',

        }}}>
             
        <InputLabel sx={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: 'black',
                  marginBottom: '9px',
                  marginTop: '19px',
                  textAlign: 'left'
              }} htmlFor='name'>Nombre(s)</InputLabel>
              <FormHelperText >Escribe correctamente tu nombre completo</FormHelperText>
              <TextField id="name" variant="filled" size='small' name='name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name}/>
              
      
              {formik.touched.name && formik.errors.name &&
      <FormHelperText error>{formik.errors.name}</FormHelperText>
      }
        <InputLabel sx={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: 'black',
                  marginBottom: '9px',
                  marginTop: '19px',
                  textAlign: 'left'
              }} htmlFor='lastName'>Apellidos</InputLabel>
              <FormHelperText>Escribe correctamente tus apellidos</FormHelperText>
              <TextField id="lastName" variant="filled" size='small'name='lastName' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.lastName} />
              {formik.touched.lastName && formik.errors.lastName &&
      <FormHelperText error>{formik.errors.lastName}</FormHelperText>
      }
              
      
      
              <p className='form__disclaimer'>Tu(s) nombre(s) y apellidos no se mostrarán a terceros en la plataforma.</p>

              {formData.target!=='4' && formData.target!=='5' && formData.target!=='6'&& <><InputLabel sx={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: 'black',
                  marginBottom: '9px',
                  
                  textAlign: 'left'
              }} htmlFor='parentName'>Nombre y apellido de madre, padre 
              o cuidador</InputLabel>
              <TextField id="parentName" variant="filled" size='small' name='parentName'onChange={formik.handleChange} onBlur={formik.handleBlur} value={formData.target!=='4' && formData.target!=='5' && formData.target!=='6'? formik.values.parentName:'NoNeeded'}/>
              {formik.touched.parentName && formik.errors.parentName &&
      <FormHelperText error>{formik.errors.parentName}</FormHelperText>
      }</>}
              <Divider  sx={{border:' 1px solid #86AF35', marginTop: '19px'}}/>
              <InputLabel sx={{
                  fontWeight: 'bold',
                  fontSize: '12px',
                  lineHeight: '15px',
                  color: 'black',
                  marginBottom: '9px',
                  marginTop: '19px',
                  textAlign: 'left'
              }} htmlFor='nick'>Tu nick o nombre para mostrar</InputLabel>
              <TextField id="nick" variant="filled" size='small' name='nickname'onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.nickname}/>
              {formik.touched.nickname && formik.errors.nickname &&
      <FormHelperText error>{formik.errors.nickname}</FormHelperText>
      }
              <p className='form__disclaimer'>Este es el nombre que se verá en la plataforma</p>

              </Box>
              <Button variant='contained' type='submit'
                sx={{
                    width: "216px",
                    height: "46px",
                    backgroundColor: "#12C185",
                    
                    
                    textTransform: 'none',
                    marginTop: "30px",
                    marginBottom: '100px',
                    borderRadius: "36px",
                    fontWeight: "900",
                    fontSize: "16px",
                    lineHeight: "24px",
                    "&:hover": {
                      background: green[300],
                    },
                
            }}
                >Siguiente</Button>
                </form>
                   <SponsorComponent/>
      </Box>
)

};

export default Form3Component;
