import { Box, Button } from '@mui/material';
import { green } from '@mui/material/colors';
import React from 'react';
import BannerComponent from './BannerComponent';
import CapsuleBannerComponent from './CapsuleBannerComponent';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import NumberComponent from './NumberComponent';
import SponsorComponent from './SponsorComponent';
import Divider from '@mui/material/Divider';
import GameComponent from './GameComponent';
const VideoComponent = ({video}) => {
  return (


        
        
                
                <iframe width={'100%'} height={500} src={video+'?rel=0'}></iframe>
    )
                
      
    
 
};

export default VideoComponent;
