import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import React, { useState } from "react";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import SponsorComponent from "./SponsorComponent";
import * as Yup from "yup";
import { sendMessage } from "../services/LevelServices";
import { useNavigate } from "react-router-dom";
const TroubleFormComponent = () => {
  const [loading, setLoading] = useState(false);
  const[alert,setAlert]=useState(false)
  const [error,setError]=useState(false)
  const[success,setSuccess]=useState(false)
  const navigate= useNavigate()
  const initialReport = {
    option: "",
    description: "",
  };
  const formik = useFormik({
    initialValues: initialReport,
    validationSchema: Yup.object().shape({
      option: Yup.string().required("Debe seleccionar una opción"),
      description: Yup.string()
        .matches(
          /^[a-zA-Z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s .]*$/,
          "Descripción solo puede contener letras y espacios"
        )
        .required("Rellene la descripción por favor"),
    }),
    onSubmit: async (values) => {
      setLoading(true)
          const resp= await sendMessage(values)
          if(!resp.error){
            setSuccess(true)
            setAlert(true)
            setLoading(false)
            setError(false)
          }
         else{
          setSuccess(false)
          setAlert(true)
          setLoading(false)
          setError(true)
         }

     
    },
  });
  return (
    <Box sx={{height:'100vh', display:'flex',flexDirection:'column'}}>
      <HeaderComponent />
      <Box
        sx={{
          width: {
            md: "80%",
            xs: "100%",
            lg: "60%",
          },

          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          flexGrow:1,
          marginRight: "auto",
          marginLeft: "auto",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant='p' className='report__title' sx={{marginTop:'30px'}}>
            Dinos dónde encontraste el problema
            </Typography>
        <form onSubmit={formik.handleSubmit} className='form__report'>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: {xs:"70%", xl:'70%'},
              alignItems: "center",
              justifyContent: "center",
              paddingLeft: "auto",
              paddingRight: "auto",
              textAlign:'left',
              alignSelf:'center',
              
            }}
          >
            
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={formik.values.option}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="option"
              >
                <FormControlLabel
                  value="tengo un problema con el login"
                  control={
                    <Radio
                      sx={{
                        color: "#12C185",
                        "&.Mui-checked": {
                          color: "#12C185",
                        },

                      }}
                    />
                  }
                  sx={{
                    fontWeight: "400",
             
              lineHeight: "15px",
              paddingTop:'12px'
                  }}
                  label="En el ingreso a la plataforma"
                />
                <FormControlLabel
                  value="tengo un problema con el contenido"
                  control={
                    <Radio
                      sx={{
                        color: "#12C185",
                        "&.Mui-checked": {
                          color: "#12C185",
                        },
                      }}
                    />
                  }
                  sx={{
                    fontWeight: "400",
           
              lineHeight: "15px",
              paddingTop:'12px'
                  }}
                  
                  label="En el contenido de una de las cápsulas de aprendizaje"
                />
                <FormControlLabel
                  value="tengo problemas con una de las actividades"
                  control={
                    <Radio
                      sx={{
                        color: "#12C185",
                        "&.Mui-checked": {
                          color: "#12C185",
                        },
                      }}
                    />
                  }
                  sx={{
                    fontWeight: "400",
              
              lineHeight: "15px",
              paddingTop:'12px'
                  }}
                  label="En una de las actividades a realizar en las cápsulas"
                />
                <FormControlLabel
                  value="otro"
                  control={
                    <Radio
                      sx={{
                        color: "#12C185",
                        "&.Mui-checked": {
                          color: "#12C185",
                        },
                      }}
                    />
                  }
                  sx={{
                    fontWeight: "400",
            
              lineHeight: "15px",
              paddingTop:'12px'
                  }}
                  label="Otro"
                />
              </RadioGroup>
              {formik.touched.option && formik.errors.option && (
                <FormHelperText error>{formik.errors.option}</FormHelperText>
              )}
            </FormControl>
            <FormControl>
              <Typography variant='p' className='report__title' sx={{marginTop:'30px', marginBottom:'20px'}}>
              Por favor describe el problema que has encontrado
de la manera más precisa posible
              </Typography>
              <TextField
          id="description"
          name='description'
          
          multiline
          rows={10}
          value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  '&.Mui-focused': {
                    borderColor: green[500],
                  }
                }}
        />
        {formik.touched.description && formik.errors.description && (
                <FormHelperText error>{formik.errors.description}</FormHelperText>
              )}
            </FormControl>

          </Box>
          {!loading && !alert && <Button
            variant="contained"
            sx={{
              width: "216px",
              height: "46px",
              backgroundColor: "#12C185",

              textTransform: "none",
              marginTop: "30px",
              marginBottom: {xs:'50px',md:'30px',xl:"90px"},
              borderRadius: "36px",
              fontWeight: "900",
              fontSize: "16px",
              lineHeight: "24px",
              "&:hover": {
                background: green[300],
              },
              mb:6
            }}
            type="submit"
          >
            Enviar
          </Button>}
        </form>
        {
              loading && <CircularProgress sx={{mb:6}}/>
            }
            {alert && success && (
          <Collapse in={alert}>
            {" "}
            <Alert
              severity="success"
              sx={{ mt: 1, mb: 4 }}
              action={
                <Button color="inherit" size="small" onClick={()=>navigate(-1)}>
                  Continuar
                </Button>
              }
            >
              <AlertTitle>Problema Enviado</AlertTitle>
              <strong>Gracias por enviar su problema</strong>
            </Alert>
          </Collapse>
        )}
            {alert && error && (
          <Collapse in={alert}>
            {" "}
            <Alert
              severity="error"
              sx={{ mt: 1, mb: 4 }}
              action={
                <Button color="inherit" size="small" onClick={()=>setAlert(false)}>
                  Reintentar
                </Button>
              }
            >
              <AlertTitle>Ocurrió un error</AlertTitle>
              <strong>Por favor vuelva a intentarlo</strong>
            </Alert>
          </Collapse>
        )}
        <SponsorComponent />
      </Box>
      <FooterComponent />
    </Box>
  );
};

export default TroubleFormComponent;
