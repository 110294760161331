import { Alert, AlertTitle, Button, Collapse, Grid, IconButton, Slider, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'

const EmotionCheckComponent = ({src}) => {
    const [checked, setChecked]=useState(false)
    const[selected,setSelected]=useState(0)
    const [alert,setAlert]=useState(false)
    const[sent,setSent]=useState(false)
    const[showIntensity,setShowIntensity]=useState(false)
  
    const marks = [
     
      {
        value: 20,
        label: 'poco',
      },
      {
        value: 60,
        label: 'bastante',
      },
      {
        value: 100,
        label: 'demasiado',
      },
    ];
    
    function valuetext(value) {
      return `${value}°C`;
    }
    
    function valueLabelFormat(value) {
      return marks.findIndex((mark) => mark.value === value) + 1;
    }
    const handleSelect=(id)=>{
       
        
        setSelected(id)
        

    }
    const handleSent=()=>{
        setChecked(true)
        setAlert(true)
        setSent(true)
        if(src.intensity && !showIntensity){
          setShowIntensity(true)
          setSent(false)
          setAlert(false)
        }
        
    }
  useEffect(()=>{
   
      setChecked(false)
        setAlert(false)
        setSent(false)
        setSelected(0)
        setShowIntensity(false)
    
  },[src])
    
  return (
    <Box sx={{
        
        width:{
            xs:'100%',
            md:'100%'
        },
        height:'fit-content',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }}>
        {/* <Typography variant='p' className="emotion__title" sx={{mt: 2,mb: 2}}>
            ¿Cómo te sientes hoy?
            </Typography> */}
        <Box sx={{
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            paddingTop:'24px',
            paddingLeft: {xs: '6px',md:'12px'},
            paddingRight: {xs: '6px',md:'12px'},
            width:'90%'
        }}>
        {!showIntensity && src.emojis.map(emoji=>(
            <Collapse orientation='horizontal' in={selected===0 || !sent || selected===emoji.id|| !checked?  true:false} key={emoji.id}>
                <Emoji className={`${selected===emoji.id? 'selected':''}`}  onClick={()=>{setSelected(emoji.id)}}>
                    <img src={emoji.src}/>
                </Emoji>
           </Collapse>
        ))}
         {showIntensity && 
         <Box sx={{
           display:'flex',
           flexDirection:'column',
           width:'100%',
           alignItems:'center',
           justifyContent:'center'
         }}>
           <Typography variant='p'sx={{margin:'12px'}}> 
             ¿Cuánto de esa emoción sientes?
           </Typography>
        
         <Box sx={{
           width:'90%',
           display:'flex',
           alignItems:'center',
           justifyContent:'space-evenly'
         }}>
           
           
           <Slider
           aria-label="Restricted values"
           defaultValue={20}
           valueLabelFormat={valueLabelFormat}
           
           step={null}
           valueLabelDisplay="auto"
           marks={marks}
         />
         
         </Box>
         </Box>
         }     
        </Box>
        <Button  variant="contained"  sx={{
                   width: '120px',
                   height:'40px',
                    backgroundColor: '#FFA903',
                    marginTop: '30px',
                    borderRadius: '36px',
                   '&:hover':{
                       background: green[300]
                   },
                   mb:2
                }}
                onClick={handleSent}
                >enviar</Button>
                {alert && <Collapse in={alert}> <Alert severity="success" 
                sx={{ mt: 1, mb: 4 }}
                action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
        <AlertTitle>Enviado</AlertTitle>
         <strong>Gracias por decirnos como te sientes</strong>
      </Alert></Collapse>}
    </Box>
  )
}

export default EmotionCheckComponent

const Emoji = styled.div`
    width: fit-content;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px;
    cursor: pointer;
    img{
width: 80px;
height: 80px;
object-fit: contain;
max-width: fit-content;
    }
    @media (max-width: 768px) {
    img{
        width: 35px;
        height: 35px;
        object-fit: contain;
    }
  }
    
`