import { Box, Link } from '@mui/material'
import React, { useState } from 'react'
import TermsModalComponent from './TermsModalComponent'

const TermsDialogComponent = () => {
    const [open,setOpen]=useState(false)
    const handleClose=()=>{
        setOpen(false)
    }
  return (
    <>
    <Box className="box1 sb5">
          Esta información no la compartiremos con terceros, solamente será utilizada para fines estadísticos, si quieres saber más sobre el uso de tus datos, haz click  
          <Link sx={{
            cursor:'pointer'
          }}
                  onClick={() => setOpen(true)}
                  className="password__link"
                >
                aquí
                </Link>
          </Box>
    <TermsModalComponent open={open} handleClose={handleClose}/>
    </>
  )
}

export default TermsDialogComponent