import { Box } from '@mui/system';
import React from 'react';

const NumberComponent = ({type, number}) => {
  return (
      <Box sx={
        {
            minWidth: '34px',
        height: '33px',
        color:`${type==='title'?'white':'black'}`,
        backgroundColor:`${type==='title'?'#FFC803':'white'}`,
        borderRadius: '90px',
        display: 'flex',
        alignItems: 'center',
        justifyContent:'center',
            margin:'0px 10px'

    }
      }>
          <p className='number'>{number}</p>
      </Box>
  );
};

export default NumberComponent;
