import { Alert, AlertTitle, Box, Button, Collapse, IconButton,Typography } from '@mui/material'
import { green, orange, red } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
const SuitcasesComponent = ({situation}) => {
    const[severity,setSeverity]=useState("error")
    const[feedback,setFeedback]=useState("Esa no es la respuesta correcta")
    const [alert,setAlert]=useState(false)
    const[imgIgualdad,setImgIgualdad]=useState(`${process.env.PUBLIC_URL}/IgualdadCerrada.png`)
    const[imgDesigualdad,setImgDesigualdad]=useState(`${process.env.PUBLIC_URL}/DesigualdadCerrada.png`)
    const verifyAnswer=(answer)=>{
        if(answer==='igualdad'){
            setImgIgualdad(`${process.env.PUBLIC_URL}/IgualdadAbierta.png`)
        }
        else{
            setImgDesigualdad(`${process.env.PUBLIC_URL}/DesigualdadAbierta.png`)
        }
        if(answer===situation.answer){
            setSeverity("success")
            setFeedback("¡Elegiste la maleta correcta!")
        }
        setAlert(true)

    }
    const InstructionsHierarchy =(instructions)=>{
      return instructions.split(';')
  
    }
    useEffect(() => {
    setSeverity("error")
    setFeedback("Esa no es la maleta correcta")  
    setAlert(false)
    setImgDesigualdad(`${process.env.PUBLIC_URL}/DesigualdadCerrada.png`)
    setImgIgualdad(`${process.env.PUBLIC_URL}/IgualdadCerrada.png`)
    }, [situation])
  return (
    <Box sx={{
        
        width:'100%',
        height:'fit-content',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }}>
      {InstructionsHierarchy(situation.sentence).map((sentence)=>(
        <Typography variant='h6'>{sentence}</Typography>
      ))}
        
       
        <Box sx={{
          width:{xs:'100%',md:'90%'},
            display:'flex',
            alignItems:'center',
            justifyContent:'space-between',
            paddingTop:'12px',
            paddingLeft: {xs: '6px',md:'12px'},
            paddingRight: {xs: '6px',md:'12px'},
            
        }}>
        
              
        
        <Box   sx={{
                   
                   width:'50%',
                    
                   
                   '&:hover':{
                       background: green[300]
                   },
                   mb:2,
                   '& img':{
                       width:'100%',
                      objectFit:'contain' 
                   }
                }}
                onClick={()=>{verifyAnswer("igualdad")}}
                >
                    <img src={imgIgualdad}/>
                </Box>
        <Box    sx={{
                 width:'50%',
                paddingLeft:'12px',  
                   
                 '&:hover':{
                     background: red[300]
                 },
                 mb:2,
                 '& img':{
                     width:'100%',
                     height:'100%', 
                    objectFit:'contain' 
                 }
                }}
                onClick={()=>{verifyAnswer("desigualdad")}}
                ><img src={imgDesigualdad}/></Box> </Box>
                {alert && <Collapse in={alert}> <Alert severity={severity} 
                sx={{ mt: 1, mb: 4 }}
                action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >

               
        <AlertTitle>Enviado</AlertTitle>
         <strong>{situation.feedback? situation.feedback:feedback}</strong>
      </Alert></Collapse>}
      
    </Box>
  )
}

export default SuitcasesComponent