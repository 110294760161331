import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FooterComponent from './FooterComponent'
import HeaderComponent from './HeaderComponent'
import QuizModalComponent from './QuizModalComponent'
import SponsorComponent from './SponsorComponent'
import { useNavigate } from 'react-router-dom'
import { insertActivity, setActivity as asignActivity } from '../services/LevelServices'

import { useDispatch } from "react-redux"
import { setActivity, setCapsule, setLevel as setLevelRedux} from "../features/Target/targetSlice"
import { getActivity, getCapsule, getLevel } from "../services/LevelServices"
const TestComponent = () => {
  const{target_id}=useParams()
  const{level_id}=useParams()
  const{type}=useParams()
var score=0
   const [finalScore, setFinalScore]=useState(0)
    const[open,setOpen]=useState(false)
    const[level,setLevel]=useState(1)
    const[next,setNext]=useState(true)

    const [questions,setQuestions]=useState(null)
    const dispatch=useDispatch()
    useEffect(()=>{
          fetch(`${process.env.PUBLIC_URL}/Questions${target_id}_${level_id}.json`)
          .then(
        (res)=>res.json()
    ).then(data=>setQuestions(data))

    .catch((err)=>{
        
    })
    },[level_id])
    const updateProgress= async (idCapsule,idActivity,idLevel)=>{
      localStorage.setItem('capsule',idCapsule)
      localStorage.setItem('level',idLevel)
      localStorage.setItem('activity',idActivity)
      const activity= await getActivity(idActivity)
      const capsule= await getCapsule(idCapsule)
      const levelLocal= await getLevel(idLevel)
     
      if(!levelLocal.error && !capsule.error && !activity.error){
        const objectLevel=levelLocal[0]
    
        dispatch(setActivity(activity[0]))

        dispatch(setCapsule(capsule[0]))
        dispatch(setLevelRedux(levelLocal[0]))
      }
  }
      const navigate= useNavigate()
      const [last,setLast]=useState(false)
      const setProgress= async (route)=>{
          
          let level_name
          switch(level.toString()){
              case '1': level_name='Básico'
                          break;
              case '2': level_name='Intermedio'
                          break;
              case '3': level_name='Experto'
                          break;
          }
          if(type==='final'){
            const progress = await asignActivity(target_id,localStorage.getItem('user'),level_name)
          
          if(!progress.error){
          
             await updateProgress(progress[0].capsule_id,progress[0].activity_id,progress[0].level_id)
              navigate(route)
          }
            navigate(route)
          }else{

          
          const progress = await asignActivity(target_id,localStorage.getItem('user'),level_name)
          
          if(!progress.error){
              
             await updateProgress(progress[0].capsule_id,progress[0].activity_id,progress[0].level_id)
              navigate(route)
          }
         }
      }
       const completeGame= async()=>{
           let activity
          switch(target_id){
              case '1': {
                      activity=622
                      
                      break;
                    } 
              case '2':  {
                activity=625
                      break;}
              case '3': {activity=628
                      break;}
              case '4': {activity=637
                      break;}
              case '5': {activity=631
                      break;}
              case '6': {activity= 634
                            break;}
            } 
            const advancedUser = await insertActivity(activity,localStorage.getItem('user'))
    
    if(!advancedUser.error){
      
      await updateProgress(advancedUser[0].capsule_id,advancedUser[0].activity_id,advancedUser[0].level_id)
      navigate('/victory')
    }
      }
      const isLast=()=>{
          let last
          switch(target_id){
              case '1': last=2
                      break;
              default: last=3
                      break;
  
          }
          if(parseInt(level)<=last){
              setLast(false)
              return false
          }
          else{
              setLast(true)
              return true
          }
      }
      const handleFinalClick= async()=>{
          if(isLast()){
              //aprobado todo
              //navigate(/victory)
              await completeGame()
          }else{
              //Seteo nivel aprobado quikeLastService
              await setProgress('/level')
              
          }
      }
      const handleClick = async()=>{
          if(isLast()){
          
              //navigate(/victory)
             await completeGame()
          }else if(next){
              
              //seteo nivel aprobado quikeLastService
              await setProgress(`/test/${target_id}/${level}/diagnosis`)
              setOpen(false)
  
          }
          else{
                  //seteo nivel actual quikeLastService
                await  setProgress('/level')
                  //navigate('/level')
          }
          
      }
    const handleSubmit=(e)=>{
        e.preventDefault()
      
       let percentageNeeded= (questions.neededScore*80)/100
       if(type==='final'){
          let percentUser=(score*100)/questions.neededScore
      
          if(percentUser>=percentageNeeded){
            
            setLevel(parseInt(level_id)+1)
          }else{
            setLevel(level_id)
          }
          setFinalScore(percentUser)
          setNext(false)
       }
       else{

      
       if(questions.neededScore>score){
         setLevel(level_id)
         setNext(false)
       }
       else{
         setLevel(parseInt(level_id)+1)
         setNext(true)
       }
     }
    
       setOpen(true)
    }
    const handleChange= (e)=>{
        let value=e.target.value
        if(e.target.checked && value==='right'){
            score++
        }
        if(!e.target.checked && value==='right'){
            score--
        }
        if(e.target.checked && value==='wrong'){
            score--
        }
        if(!e.target.checked && value==='wrong'){
            score++
        }
       
    }
  return (
    <Box sx={{display: 'flex', flexDirection:'column', height:'100vh' }}>
      <HeaderComponent/>
      
      <Box
        sx={{
          width: {
            md: "80%",
            xs: "100%",
            lg: "60%",
          },
          
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          flexGrow :1,
          marginRight: "auto",
          marginLeft: "auto",
          position: "relative",
          alignItems:'center',
          
        }}
      >
        
        <Typography variant='h5' className='terms__title' sx={{mt:'24px'}}>Evaluación</Typography>
     {open &&
        <div>
    

        <Box sx={{
            width:'60%',
            flexGrow: 1,
           height:'40vh',
            display:'flex',
            alignItems: 'center',
            justifyContent:'center',
            flexDirection:'column',
            marginLeft: 'auto',
            marginRight:'auto'
        }}>
        <Box
      sx={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/tatu-fin 1.png')`,
        width: '90%',
        height: '163px',
        paddingTop: {md:"24px",xs:'12px'},
        PaddingBottom: "0",
        paddingRight: "50px",
        paddingLeft:'20px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "contain",
        flexDirection: 'column',
        textAlign: 'center',
        marginBottom: '12px',
       
      }}
    >



    </Box>
    {type==='final' && <Typography variant='p' className='terms__title' sx={{fontSize:{
md:'1rem',
xs: '0.56rem'
}}}> Tu puntaje es de : {finalScore}</Typography>}
    { type!=='final' && <Typography variant='p' className='terms__title' sx={{fontSize:{
md:'1rem',
xs: '0.56rem'
}}}>
{next ? 'Has aprobado este Nivel, ¡Continuemos!':last? '¡Eres Un experto!': 'Repasemos un poco más este nivel'} 
</Typography>}
        </Box>


    {
        !next &&
        <Button variant='contained' sx={{
        marginInline: 'auto',
        backgroundColor: "#12C185",
        borderRadius: '12px',
        textTransform: 'none',
        width: '124px',
        height: '32px',
        marginBottom: '32px',
        marginTop: '22px',
        "&:hover": {
            background: green[300],
          },
    }} onClick={type==='final'?handleFinalClick:handleClick}>¡Vamos!</Button>}
        
     {next &&   <Button variant='contained' sx={{
        marginInline: 'auto',
        backgroundColor: "#12C185",
        borderRadius: '12px',
        textTransform: 'none',
        width: '130px',
        height: '32px',
        marginBottom: '32px',
        marginTop: '32px',
        "&:hover": {
            background: green[300],
          },
    }} onClick={type==='final'?handleFinalClick:handleClick}>{last?'¡Vamos!':'Siguiente nivel'}</Button>

    }
    

</div>
     
     
     }
        {!open &&<form onSubmit={handleSubmit} className='form__register'>
            {questions && questions.questions.map(question=>(
                <FormControl sx={{ m: 3, width:'80%' }} component="fieldset" variant="standard" key={question.id}>
                <FormLabel component="legend" sx={{textAlign:'left', margin:'12px'}}>{question.question}</FormLabel>
                {question.img && <img src={question.img} width="320px" height="auto"/>}
                <FormGroup>
                    {question.options.map(option=>(
                        <FormControlLabel
                    control={
                      <Checkbox onChange={handleChange}   value={option.value}  sx={{
                        color: green[400],
                        '&.Mui-checked': {
                          color: '#12C185',
                        },
                      }}/>
                    }
                    label={option.text.includes('https://')?
                    <img src={option.text} width="120px" height="auto" style={{marginRight: "5px"}}/>:<Typography sx={{textAlign:'left'}}>{option.text}</Typography>}
                key={option.text}  />
                
                    ))}
                  
                </FormGroup>
            </FormControl>
            ))}
    <Button variant='contained'
                sx={{
                    width: "216px",
                    height: "46px",
                    backgroundColor: "#12C185",
                    
                    
                    textTransform: 'none',
                    marginTop: "30px",
                    marginBottom: '80px',
                    borderRadius: "36px",
                    fontWeight: "900",
                    fontSize: "16px",
                    lineHeight: "24px",
                    "&:hover": {
                      background: green[300],
                    },
                
            }}
            type='submit'>Revisar</Button>
</form>}
<SponsorComponent/>
</Box>
<FooterComponent/>

    </Box>
  )
}

export default TestComponent