import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { selectTarget,selectCapsule,selectLevel } from '../features/Target/targetSlice';
import { getLevel, getLevelCapsules } from '../services/LevelServices';
import BannerComponent from './BannerComponent';
import CapsuleComponent from './CapsuleComponent';
import ErrorComponent from './ErrorComponent';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import MascotComponent from './MascotComponent';
import SponsorComponent from './SponsorComponent';

const CapsuleSelectionComponent = () => {
  const history = useNavigate()
  const target = useSelector(selectTarget)
  const actualCapsule= useSelector(selectCapsule)
  const actualLevel = useSelector(selectLevel)
  const [level,setLevel]=useState({})
  const [capsules,setCapsules]=useState([])
  const [error,setError]=useState(false)
  const[capsuleNum,setCapsuleNum]=useState(0)
  const {id} = useParams()
  const levelNumber = ()=>{
    switch(level.name){
      case 'Básico': return '1'
      case 'Intermedio': return '2'
      case 'Experto': return '3'
    }
  }
  const state=(num)=>{
    if(parseInt(actualLevel.id)< parseInt(id)){
      
      return 'bloqueado'
    }
    if(parseInt(actualLevel.id)> parseInt(id)){ return 'completado'}
   
    if(capsuleNum>parseInt(num)){ return 'completado'}
    else if(capsuleNum<parseInt(num)) {return 'bloqueado';}
    else  return 'en proceso'
  }
  const demo=(ages)=>{
    if(ages==='6 a 8 años' || ages==='9 a 12 años') return 'niñas y niños'
    else if(ages==='13 a 19 años') return 'jóvenes'
    else return ''
  }
  const resp= async()=>{
   const level= await getLevel(id)
  
       
   const capsules= await getLevelCapsules(id)
     
    if(!level.error && !capsules.error){
      setLevel(level[0])
      setCapsules(capsules)
      setError(false)
    }
    else{
      setError(true)
    }
  }
  useEffect(() => {
    resp()
    if(actualCapsule){
      setCapsuleNum(actualCapsule.num)
    }
       
},[actualCapsule])
  return <Box  >
      <HeaderComponent/>
      {error && <ErrorComponent/>}
      {!error &&<Box
        sx={{
           width:{
            md: '80%',
            xs:'100%',
           lg:'70%',
           xl:'80%'
        },
      
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent:'center',
          alignItems:'center',
          marginRight: "auto",
          marginLeft:'auto',
          position: "relative",
       
         
        }}
      >
     <BannerComponent demo={demo(target.name)} link={true} level={level.name} name={target.name} color={target.color} img={`${target.img}`}/>
        <Box sx={{
           marginBottom: '110px',
          width:'100%',
          
        }}>
          {actualCapsule.num==='1' &&<DialogContainer>
          <Box className=''>

            <p>Quiero hacer mi evaluación diagnóstica</p>
            <Button variant='contained' onClick={()=>history('/test/'+target.id+'/'+levelNumber()+'/diagnostic')} sx={{ backgroundColor:'#12c185', marginTop: '12px'}}>¡Vamos!</Button>
          </Box>
          <ImageContainer>
          <img
              src={`${process.env.PUBLIC_URL}/mascota 1.png`}
              className="form__image"
            />
          </ImageContainer>
          </DialogContainer>}
        <Grid container spacing={0} sx={{paddingLeft: {xs:'12px',md:'0px'}}}  >
          {capsules && capsules.map((capsule, index)=>
         { 
          return (
            <Grid item xs={10} md={6} xl={4} key={capsule.id} >
          {capsuleNum>0 && <CapsuleComponent title={capsule.name} state={state(capsule.num)} color={target.color} number={index+1} id={capsule.id} image={capsule.img}/>  }
        </Grid>
          )})}
        </Grid> 
      </Box>
        {/* <MascotComponent/> */}
        <SponsorComponent/>
        </Box>}
        <FooterComponent/>
  </Box>;
};

export default CapsuleSelectionComponent;
const ImageContainer=styled.div`
    width:10%;
    margin-left: 12px;
    border-radius: 50%;
    border: 3px solid #86AF35;
    overflow: hidden;
    margin-right:auto;
    display:flex;
`
const DialogContainer= styled.div`
width:100%;
display: flex;
align-items:center;
justify-content:center;

`