import { Box, Typography } from '@mui/material'
import React from 'react'

const ErrorComponent = () => {
  return (
    <Box sx={{
        display:'flex',
        width:'90%',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }}>
        <Typography variant='h4'>
            ¡Ha ocurrido un error!
        </Typography>
        <Typography variant='h6'>
            Por favor intenta nuevamente
        </Typography>
    </Box>
  )
}
export default ErrorComponent