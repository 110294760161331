import { Box } from '@mui/material';
import React from 'react';

const SponsorComponent = () => {
  return (                  
  <Box sx={{
    backgroundImage:`url('${process.env.PUBLIC_URL}/footer.jpg')`,
    
    width: {md:'98%',xs:'95%',xl:'90%'},
   marginLeft:'8px',
    backgroundSize: 'contain',
    height: {md:'70px',lg:'80px',xs:'50px'},
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center',
   marginTop:'110px',
    bottom:'0%',
    left: '0',
    position:{xs:'static',xl:'absolute'},
    zIndex:'0',
    marginBottom:'12px'
}}>
    
</Box>  );
};

export default SponsorComponent;
