import { Alert, Button, Input, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'

import axios from 'axios'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import styled from 'styled-components'
import SaveIcon from '@mui/icons-material/Save'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors'
const DocumentComponent = ({src}) => {
    const[file,setFile]=useState()
    const[loading,setLoading]=useState(false)
    const[alert,setAlert]=useState(false)
    function handleChange(event) {
        setFile(event.target.files[0])
      }
      
      async function  handleSubmit () {
        setLoading(true)
        // const url = 'http://localhost:3000/uploadFile';
        // const formData = new FormData();
        // formData.append('file', file);
        // formData.append('fileName', file.name);
        // const config = {
        //   headers: {
        //     'content-type': 'multipart/form-data',
        //   },
        // };
        // await axios.post(url, formData, config).then((response) => {
        //   console.log(response.data);
        // });
        setTimeout(fileUploaded, 4000);
    
      }
      const fileUploaded=()=>{
            setLoading(false)
            setAlert(true)
            
      }
  return (
    <Box sx={{
        
        width:{
            xs:'100%',
            md:'100%'
        },
        height:'200px',
        borderRadius:'29px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center'
    }}>
        {
            src.links.map((link)=>(
                <LinkContainer>
                <Link href={link} target='_blank'>
                    Modelos 
                </Link>
                </LinkContainer>
            ))
        }
        <Box sx={{
            display:'flex',
            alignItems:'center'
        }}>
        {file&&<AttachFileIcon/>}
        <Typography variant='h6' sx={{
            margin: '12px'
        }}>
            {file? file.name: "No ha cargado ningun archivo"}
        </Typography>
        </Box>
        <label htmlFor="contained-button-file">
        <Input accept="image/*" id="contained-button-file"  type="file" onChange={handleChange} style={{display:'none'}}/>
        {!loading && !file &&<Button variant="contained" component="span" type='submit' startIcon={<FileUploadIcon/>}onClick={handleChange} sx={{
           width: "216px",
           height: "46px",
           backgroundColor: "#12C185",
           
           
           
         
           
           borderRadius: "36px",
           fontWeight: "900",
           fontSize: "16px",
           lineHeight: "24px",
           "&:hover": {
             background: green[300],
           },
        }}>
          Subir
        </Button>
        }
      </label>
         {!loading && file && !alert&&<Button variant="contained" component="span" type='submit' startIcon={<SaveIcon/>}onClick={handleSubmit} sx={{
            width: "216px",
            height: "46px",
            backgroundColor: "#12C185",
            
            
            
          
            
            borderRadius: "36px",
            fontWeight: "900",
            fontSize: "16px",
            lineHeight: "24px",
            "&:hover": {
              background: green[300],
            },
         }}>
          Guardar
        </Button>
        }
        {loading &&
         <CircularProgress sx={{color:'#12C185'}}/>
        }
        {
          alert &&  <Alert severity="success">Su archivo fue guardado correctamente!</Alert>
        }
    </Box>
  )
}

export default DocumentComponent

const Link= styled.a`
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;

`
const LinkContainer= styled.div`
   width: 60%;
   height: 60px;
   border-radius: 11px;
   border: 4px solid #12C185;
   background-color: white; 
   display:flex;
   align-items:center;
   justify-content: center;
   cursor:pointer;
`