import { Box, Button, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectTarget } from '../features/Target/targetSlice'
import { getLibrary } from '../services/LevelServices'
import BannerComponent from './BannerComponent'
import FooterComponent from './FooterComponent'
import HeaderComponent from './HeaderComponent'
import SponsorComponent from './SponsorComponent'

const LibraryComponent = () => {
    const target= useSelector(selectTarget)
    const [library,setLibrary]=useState([])
    const navigate=useNavigate()
    const demo=(ages)=>{
        if(ages==='6 a 8 años' || ages==='9 a 12 años') return 'niñas y niños'
        else if(ages==='13 a 19 años') return 'jóvenes'
        else return ''
      }
      const fillLibrary= async()=>{
          const library= await getLibrary(target.id)
          if(!library.error){
              setLibrary(library)
          }
      }
      useEffect(() => {
        fillLibrary()
      
        
      }, [target])
      
  return (
    <Box sx={{display:'flex',flexDirection:'column', height:'100vh'}}>
        <HeaderComponent/>
        <Box
        sx={{
           width:{
            md: '80%',
            xs:'100%',
            lg:'60%',
            xl:'70%'
        },

          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          
          marginLeft: "auto",
          marginRight:'auto',
          position: "relative",
          
        }}
      >
        <BannerComponent demo={demo(target.name)} name={target.name} color={target.color} img={target.img}/>
        <Typography className='terms__title' sx={{mt:2,mb:2}}>Biblioteca</Typography>
        {library.length>0 && library.map(resource=>(
            <div key={resource.id}>
            <Typography >
                <a href={resource.link} className='password__link'>
                    
                {resource.name}
                </a>
                </Typography>
                <Typography className='terms' sx={{textAlign: 'center', fontSize:'12px'}}>
                    {resource.source}
                </Typography>
            </div>
        ))}
        <Button variant='contained'
                sx={{
                    width: "216px",
                    height: "46px",
                    backgroundColor: "#12C185",
                    
                    marginLeft:'auto',
                    marginRight:'auto',
                    textTransform: 'none',
                    marginTop: "30px",
                    marginBottom: '80px',
                    borderRadius: "36px",
                    fontWeight: "900",
                    fontSize: "16px",
                    lineHeight: "24px",
                    "&:hover": {
                      background: green[300],
                    },
                
            }}
            onClick={()=>(navigate(-1))}>Atrás</Button>
            <SponsorComponent/>
        </Box>
        <FooterComponent/>
    </Box>
  )
}

export default LibraryComponent