import { Box, Button, Typography } from "@mui/material";

import React, { useEffect, useState } from "react";
import ErrorComponent from './ErrorComponent';
import CapsuleBannerComponent from "./CapsuleBannerComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import NumberComponent from "./NumberComponent";
import SponsorComponent from "./SponsorComponent";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import { selectActivity, selectCapsule, selectLevel, selectTarget } from "../features/Target/targetSlice";
import { useNavigate, useParams } from "react-router-dom";
import VideoComponent from "./VideoComponent";
import GameComponent from "./GameComponent";
import MascotComponent from "./MascotComponent";
import ActivityModal from "./ActivityModal";
import ComicComponent from "./ComicComponent";
import EmotionCheckComponent from "./EmotionCheckComponent";
import ChallengeComponent from "./ChallengeComponent";
import ContentComponent from "./ContentComponent";
import { getActivity, getCapsule, getContentsActivity, getLevel } from "../services/LevelServices";
const ActivityComponent = () => {
  const { id, capsuleId } = useParams();
  const history = useNavigate();
  const target = useSelector(selectTarget);
  const actualActivity =useSelector(selectActivity)
  const actualLevel = useSelector(selectLevel)
  const actualCapsule = useSelector(selectCapsule)
  const [capsule, setCapsule] = useState({});
  const [activity, setActivity] = useState({});
  const [capsuleNumber, setCapsuleNumber] = useState(0);
  const [contents, setContents] = useState([]);
  const [level, setLevel] = useState({});
  const [open, setOpen] = useState(false);
  const [error,setError]=useState(false)
  const [type,setType]=useState('video')
  const handleClose = () => {
    setOpen(false);
  };
  const openModal = () => {
     
    setOpen(true);
  };
  const demo=(ages)=>{
    if(ages==='6 a 8 años' || ages==='9 a 12 años') return 'niñas y niños'
    else if(ages==='13 a 19 años') return 'jóvenes'
    else return ''
  }
  const helperText=()=>{
    switch(type){
      case 'Video': return'¡Hola! Lee bien las instrucciones, en esta cápsula tienes que poner atención al vídeo.'
      case 'Cartilla': return'¡Hola! En esta cápsula debes ver el contenido con mucha atención.'
      case 'Juego': return'¡Hola! Lee bien las instrucciones y realiza la actividad a continuación. ¡A divertirse!'
      case 'Audio': return '¡Hola! Lee bien las instrucciones y escucha el audio con mucha atención.'
      case 'Evaluaciones': return'¡Hola! Es hora de poner a prueba lo que aprendimos.'
      case 'Reto': return '¡Hola! Lee bien las instrucciones y completa el reto.'
      case 'Emociones': return '¡Hola! Lee bien las instrucciones y realiza la actividad a continuación. ¡A divertirse!'
    }
  }
  const resp = async()=>{
    const content= await getContentsActivity(id)
    const activity= await  getActivity(id)
    const capsule = await   getCapsule(activity[0].capsule_id)
    const level = await getLevel(capsule[0].level_id)
    if(!content.error && !activity.error && !capsule.error && !level.error)  
     { 
       
        setContents(content)
        setActivity(activity[0])
        setCapsule(capsule[0])
        setLevel(level[0])
        setType(activity[0].type)
        setError(false)
      }
    else{
        setError(true)
    }
       
  }
  const isCompleted=()=>{
    if(actualLevel.id > level.id || actualCapsule.num> capsule.num || actualActivity.num > activity.num) { return true}
    else{
      return false
    }
  }
  useEffect(() => {
    resp()
  }, [id]);
  return (
    <div>
      <HeaderComponent />
      {error && <ErrorComponent/>}
      {!error && <Box
        sx={{
          width: {
            md: "80%",
            xs: "100%",
            lg: "60%",
          },

          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          height:{xl:'-webkit-fill-available'},
          marginRight: "auto",
          marginLeft: "auto",
          position: "relative",
        }}
      >
        <CapsuleBannerComponent
        demo={demo(target.name)}
          name={target.name}
          color={target.color}
          img={capsule.img}
          number={capsule.num}
          title={capsule.name}
          levelId={level.id}
        />
        <Box
          sx={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "100px",
            marginRight: "auto",
            marginLeft: "auto",
            paddingTop: "12px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
              marginBottom: "18px",
            }}
          >
            <NumberComponent type={"title"} number={activity.num} />
            <p className="capsuleItem__title">{activity.alias}</p>
          </Box>
          <Divider
            variant="middle"
            style={{ width: "100%", marginBottom: "12px" }}
          />
          {/* {activity.script&&<>
          <Typography variant='p' className='activity__instructions'>
            {activity.script}
          </Typography>
          <Divider
            variant="middle"
            style={{ width: "100%", marginBottom: "12px" }}
          />
          </>} */}
          {contents.length>0 && <ContentComponent contents={contents} target={target} capsule={capsule.id} state = {isCompleted()} activity={id}/>}
          
          <MascotComponent click={openModal} />
        </Box>
        <SponsorComponent />
      </Box>}
      <ActivityModal
        open={open}
        handleClose={handleClose}
        helperText={helperText()}
      />
      <FooterComponent />
    </div>
  );
};

export default ActivityComponent;
