import { Box, Button, Dialog, DialogActions, DialogContent, Divider, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ActivityModal = ({open, handleClose,helperText}) => {
    const navigate= useNavigate()
  return <div>
      <Dialog 
      open={open}
      onClose={handleClose}>
          <DialogContent>
                <Box sx={{
                    width:'100%',
                    display:'flex',
                    alignItems: 'center',
                    justifyContent:'center'
                }}>
                <Box
              sx={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/Dialoghelper.png)`,
                width: '50%',
                height: '163px',
                paddingTop: {md:"24px",xs:'12px'},
                PaddingBottom: "0",
                paddingRight: "50px",
                paddingLeft:'20px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                flexDirection: 'column',
                textAlign: 'center',
                marginBottom: '12px'
              }}
            >
    
<Typography variant='p' sx={{fontSize:{
    md:'1rem',
    xs: '0.56rem'
}}}>
{helperText}
</Typography>
 
            </Box>
            <Box sx={{
                width: '20%',
                marginLeft: '12px',
                borderRadius: '50%',
                border: '3px solid #86AF35',
                overflow: 'hidden'
            }}>
               <img src={`${process.env.PUBLIC_URL}/mascota 1.png`} className='form__image'/> 
            </Box>
                </Box>
        <Divider sx={{border:'1px solid #89B237'}}/>
        <Box sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            justifyContent:'center',

        }}>
            <p className='modal__question'>¿En qué más puedo ayudarte?</p>
            <a className='modal__link' onClick={()=>navigate('/troubleForm')}>He encontrado un problema con el funcionamiento de la plataforma</a>
            <a className='modal__link' onClick={()=>navigate('/reportForm')}>Quiero reportar un detalle de la plataforma</a>
            <a className='modal__link'onClick={()=>navigate('/questionForm')}>Tengo una pregunta</a>
            <Divider variant= 'middle' sx={{border: '1px solid #89B237',width: '60%',marginTop: '12px', marginBottom: '12px'}}/>
            <a className='modal__link'onClick={()=>navigate('/personalForm')}>Quiero hablar de algo que me está pasando</a>
        </Box>
        </DialogContent>
        <DialogActions>
            <Button variant='contained' sx={{
                marginInline: 'auto',
                backgroundColor: "#12C185",
                borderRadius: '12px',
                textTransform: 'none',
                width: '124px',
                height: '32px',
                marginBottom: '32px',
                "&:hover": {
                    background: green[300],
                  },
            }} onClick={handleClose}>Cerrar</Button>
   
          
         
        </DialogActions>
      </Dialog>
  </div>;
};

export default ActivityModal;
