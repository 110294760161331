import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectActivity, selectCapsule, selectTarget } from "../features/Target/targetSlice";
import { getCapsule, getCapsulesActivities, getLevel } from "../services/LevelServices";
import CapsuleBannerComponent from "./CapsuleBannerComponent";
import CapsuleItemComponent from "./CapsuleItemComponent";
import ErrorComponent from "./ErrorComponent";
import FooterComponent from "./FooterComponent";
import HeaderComponent from "./HeaderComponent";
import MascotComponent from "./MascotComponent";
import NumberComponent from "./NumberComponent";
import SponsorComponent from "./SponsorComponent";

const CapsuleContentComponent = () => {
  const target = useSelector(selectTarget);
  const { id } = useParams();
  const [level, setLevel] = useState({});
  const [activities, setActivities] = useState([]);
  const [capsule, setCapsule] = useState({});
  const actualCapsule = useSelector(selectCapsule)
  const history = useNavigate();
  const actualActivity = useSelector(selectActivity)
  const[activityNum,setActivityNum]=useState(actualActivity.num)
  const [error,setError]=useState(false)

  const state=(num)=>{
    
    if(parseInt(localStorage.getItem('level'))> parseInt(capsule.level_id)){
      return 'completado'
    }
    if(activityNum>num || capsule.num<parseInt(actualCapsule.num)){ return 'completado'}
    else if(activityNum<num) {return 'bloqueado';}
    else return 'en proceso'
  }
  const resp = async()=>{
    const capsule =await getCapsule(id)
    
      
    const level= await  getLevel(capsule[0].level_id)
      
        
      
     const activities = await getCapsulesActivities(id)
      
        
      if(!capsule.error && !level.error && !activities.error){
        setCapsule(capsule[0])
        setLevel(level[0])
        setActivities(activities)
        setError(false)
        
      }
      else{
        setError(true)
      }
    
  }
  const demo=(ages)=>{
    if(ages==='6 a 8 años' || ages==='9 a 12 años') return 'niñas y niños'
    else if(ages==='13 a 19 años') return 'jóvenes'
    else return ''
  }
  useEffect(() => {
  resp()
   
    if(actualActivity.num){
     
      setActivityNum(actualActivity.num)
    }
    
  }, [actualActivity]);
  return (
    <Box >
      <HeaderComponent />
      {error && <ErrorComponent/>}
      {!error && <Box
        sx={{
          width: {
            md: "80%",
            xs: "100%",
            lg: "60%",
          },

          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          height:{xl:'-webkit-fill-available'},
          marginRight: "auto",
          marginLeft:'auto',
          position: "relative",
        }}
      >
        <CapsuleBannerComponent
        demo={demo(target.name)}
          name={target.name}
          color={target.color}
          img={capsule.img}
          level={level.name}
          number={capsule.num}
          title={capsule.name}
          levelId={level.id}
        />
        <Box sx={{width:'100%',marginBottom:'70px'}}>

        
        
        <Grid container spacing={0} mb={10} sx={{ justifyContent: "center" }}>
            {activities && activities.map((activity,index)=>{
                return(
                    <Grid item xs={10} md={8} key={activity.id}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <NumberComponent type={"title"} number={index+1} />
              {activityNum>0 &&<CapsuleItemComponent
                state={state(activity.num)}
                title={activity.alias}
                type={activity.type}
                typeText= {activity.typeText}
                color={target.color}
                id={activity.id}
                capIdd={capsule.id}
                actualLevel={level.name}
              />}
            </Box>
          </Grid>
        
                )
            })}
        </Grid>
      </Box>
        {/* <MascotComponent /> */}
        <SponsorComponent />
      </Box>}

      <FooterComponent />
    </Box>
  );
};

export default CapsuleContentComponent;
