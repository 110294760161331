import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import BackLinkComponent from './BackLinkComponent';
import './Components.css';

const BannerComponent = ({demo,name,color,img,link,level}) => {
   
    
  return (
      <Box sx={{
          display: 'flex',
          width: '100%',
         
          backgroundColor: `${color}`,
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px'
      }}>
        <Box
            sx={{
                display: 'flex',
                width: '50%',
                
                flexDirection:'column',
                justifyContent: 'center',
                alignItems:'center'
            }}
        >
            {link && <BackLinkComponent/>}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignContent:'center',
                justifyContent:'center',
                height: '100%'
            }}>

                <Typography variant='p' className="Banner__title" sx={{
                    fontSize:{
                        xs: '1.2em',
                        xl:'2.5em'
                    }
                }}>{demo}</Typography>
                <Typography variant='p' className='Banner__age' sx={{
                    fontSize:{
                        xs:'1.5em',
                        xl:'2.5em'
                    }
                }}>{name}</Typography>
                
                {level && <Box sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: 'white',
                      color: '#12C185',
                      fontWeight: '900',
                        fontSize: {md:'20px', xs:'14px'},
                        lineHeight: '24px',
                        borderRadius: '40px',
                        paddingTop: {md:'20px',xs:'10px'},
                        paddingBottom: {md:'20px',xs:'10px'},
                        paddingLeft: {md:'12px',xs:'8px'},
                        paddingRight: {md:'12px',xs:'8px'},
                        marginBottom: '24px',
                       textTransform:'uppercase'
                  }}>
                      <p>Nivel {level}</p>
                  </Box>}
            </Box>
        </Box>
        <Box
            sx={{
                width: '50%',
                
                objectFit: 'conver',
                display: 'flex'
            }}
            
        >
            <img src={img} className="Banner__image"/>
        </Box>

      </Box>
  );
};

export default BannerComponent;
