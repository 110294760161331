import axios from 'axios'

export const getTargetLevels = async(targetId)=>{
    let formData = new FormData()
   
    formData.append('target_id',targetId)
   try{

    return await axios.post(' https://www.mqmc.org/cemse_service/levels.php',formData,
    { headers: { Accept: "application/json" } }
  )
  .then((response) => {
    
    return response.data;
  })
    }catch(error){
      let errorCaught={
        error:'error',
        type:'Something went wrong',
        message:'Ha ocurrido un error intente nuevamente'
      }
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        
       errorCaught.type='server failed'
        return errorCaught
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        
        errorCaught.type='server not responding'
        return errorCaught
      } else {
        // Something happened in setting up the request that triggered an Error
        
        errorCaught.type='request triggered an error'
        return errorCaught
      }
      
  }
}
export const getTarget = async(id)=>{
  let formData = new FormData()
  formData.append('id',id)
 try{

 return await axios.post(' https://www.mqmc.org/cemse_service/targets.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.console.log(error.request);
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
      
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const getComments = async(id)=>{
 
  let formData = new FormData()
  formData.append('content_id',id)
 try{

 return await axios.post(' https://www.mqmc.org/cemse_service/comments.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {

  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
     
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
      
      errorCaught.type='request triggered an error'
      return errorCaught
    }
  
}
}
export const postComment = async(values)=>{
  let formData = new FormData()
  formData.append('content_id',values.content_id)
  formData.append('user_id',values.user_id)
  formData.append('msg',values.message)
  formData.append('nick',values.nick)
 try{

 return await axios.post(' https://www.mqmc.org/cemse_service/insert_comment.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
     
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const getLevel = async(id)=>{
    let formData = new FormData()
    formData.append('id',id)
   try{

   return await axios.post(' https://www.mqmc.org/cemse_service/levels.php',formData,
    { headers: { Accept: "application/json" } }
  )
  .then((response) => {
    return response.data;
  })
    }catch(error){
      let errorCaught={
        error:'error',
        type:'Something went wrong',
        message:'Ha ocurrido un error intente nuevamente'
      }
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
      
       errorCaught.type='server failed'
        return errorCaught
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      
        errorCaught.type='server not responding'
        return errorCaught
      } else {
        // Something happened in setting up the request that triggered an Error
        
        errorCaught.type='request triggered an error'
        return errorCaught
      }
     
  }
}
export const getCapsule = async(id)=>{
    let formData = new FormData()
    formData.append('id',id)
   try{

    return await axios.post(' https://www.mqmc.org/cemse_service/capsules.php',formData,
    { headers: { Accept: "application/json" } }
  )
  .then((response) => {
    return response.data;
  })
    }catch(error){
      let errorCaught={
        error:'error',
        type:'Something went wrong',
        message:'Ha ocurrido un error intente nuevamente'
      }
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
       
       errorCaught.type='server failed'
        return errorCaught
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
      
        errorCaught.type='server not responding'
        return errorCaught
      } else {
        // Something happened in setting up the request that triggered an Error
       
        errorCaught.type='request triggered an error'
        return errorCaught
      }
     
      
  }
}
export const getActivity = async(id)=>{
    let formData = new FormData()
    formData.append('id',id)
   try{

   return await axios.post(' https://www.mqmc.org/cemse_service/activities.php',formData,
    { headers: { Accept: "application/json" } }
  )
  .then((response) => {
    return response.data;
  })
    }catch(error){
    
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
   
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
      
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
  }
}
export const getLevelCapsules = async(levelId)=>{
    let formData = new FormData()
    formData.append('level_id',levelId)
   try{

   return await axios.post(' https://www.mqmc.org/cemse_service/capsules.php',formData,
    { headers: { Accept: "application/json" } }
  )
  .then((response) => {
    return response.data.sort((a,b)=>a.num-b.num);
  })
    }catch(error){
      let errorCaught={
        error:'error',
        type:'Something went wrong',
        message:'Ha ocurrido un error intente nuevamente'
      }
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
     
       errorCaught.type='server failed'
        return errorCaught
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
       
        errorCaught.type='server not responding'
        return errorCaught
      } else {
        // Something happened in setting up the request that triggered an Error
      
        errorCaught.type='request triggered an error'
        return errorCaught
      }
    
  }
}
export const getCapsulesActivities = async(capsuleId)=>{
    let formData = new FormData()
    formData.append('capsule_id',capsuleId)
   try{

   return await axios.post(' https://www.mqmc.org/cemse_service/activities.php',formData,
    { headers: { Accept: "application/json" } }
  )
  .then((response) => {
    return response.data.sort((a,b)=>a.num-b.num);
  })
    }catch(error){
      let errorCaught={
        error:'error',
        type:'Something went wrong',
        message:'Ha ocurrido un error intente nuevamente'
      }
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        
       errorCaught.type='server failed'
        return errorCaught
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
       
        errorCaught.type='server not responding'
        return errorCaught
      } else {
        // Something happened in setting up the request that triggered an Error
       
        errorCaught.type='request triggered an error'
        return errorCaught
      }
     
  }
}
export const getContentsActivity = async(activityId)=>{
    let formData = new FormData()
    formData.append('activity_id',activityId)
   try{

  return  await axios.post(' https://www.mqmc.org/cemse_service/content.php',formData,
    { headers: { Accept: "application/json" } }
  )
  .then((response) => {
    return response.data;
  })
    }catch(error){
      let errorCaught={
        error:'error',
        type:'Something went wrong',
        message:'Ha ocurrido un error intente nuevamente'
      }
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
      
       errorCaught.type='server failed'
        return errorCaught
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
       
        errorCaught.type='server not responding'
        return errorCaught
      } else {
        // Something happened in setting up the request that triggered an Error
        
        errorCaught.type='request triggered an error'
        return errorCaught
      }
      
  }
}

export const Login = async(values)=>{
  let formData = new FormData()
  formData.append('nickname',values.email)
  formData.append('password',values.password)
 try{

  return await axios.post(' https://www.mqmc.org/cemse_service/login_user.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx

     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
     
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
   
    
}
}
export const Register = async(values)=>{
  
  let formData = new FormData()
  formData.append('name',values.name)
  formData.append('lastName',values.lastName)
  formData.append('parentName',values.parentName)
  formData.append('password',values.password)
  formData.append('email',values.email)
  formData.append('gender',values.gender)
  formData.append('birthDate',values.birthDate._i)
  formData.append('target_id',values.target)
  formData.append('nickname',values.nickname)
  formData.append('municipality',values.municipio)
  formData.append('city',values.departamento)
  formData.append('school',values.unidadEducativa)
  
 try{

  return await axios.post(' https://www.mqmc.org/cemse_service/register_user.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
   
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
   
    
}
}

export const insertActivity = async(activity,user)=>{
 
  let formData = new FormData()
  formData.append('activity_id',activity)
  formData.append('user_id',user)
 try{

  return await axios.post(' https://www.mqmc.org/cemse_service/insert_activity.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
    
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
   
      errorCaught.type='request triggered an error'
      return errorCaught
    }
   
    
}
}
export const endActivity = async(activity,user,level,capsule)=>{
  let formData = new FormData()
  formData.append('activity_id',activity)
  formData.append('level_id',level)
  formData.append('capsule_id',capsule)
  formData.append('user_id',user)
 try{

  return await axios.post(' https://www.mqmc.org/cemse_service/end_activity.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
     
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
     
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
   
    
}
}
export const setActivity = async(target,user,level)=>{

  let formData = new FormData()
  formData.append('target_id',target)
  formData.append('level_name',level)
  formData.append('user_id',user)
 try{

  return await axios.post(' https://www.mqmc.org/cemse_service/new_activity.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
   
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js

      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
 
      errorCaught.type='request triggered an error'
      return errorCaught
    }
   
    
}
}

export const getLibrary = async(targetId)=>{
  let formData = new FormData()
  formData.append('target_id',targetId)
 try{

return  await axios.post(' https://www.mqmc.org/cemse_service/biblioteca.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const getCity = async()=>{
  
 try{

return  await axios.get('https://mqmc.org/cemse_service/cities.php',
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const sendMessage = async(values)=>{
 
  let user_id=localStorage.getItem('user')
  console.log(user_id)
  let formData = new FormData()
  formData.append('option',values.option)
  formData.append('description',values.description)
  formData.append('user_id',user_id)
 try{

  return await axios.post(' https://www.mqmc.org/cemse_service/send_msg.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
     
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
     
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Error
      
      errorCaught.type='request triggered an error'
      return errorCaught
    }
   
    
}
}

export const googleLogin = async(tokenId)=>{
  let formData = new FormData()
  formData.append('id_token',tokenId)
 try{

return  await axios.post(' https://www.mqmc.org/cemse_service/login_google.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const getMunicipality = async(cityId)=>{
  let formData = new FormData()
  formData.append('idcity',cityId)
 try{

return  await axios.post('https://mqmc.org/cemse_service/municipalities.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const getSchool = async(municipalityId)=>{
  let formData = new FormData()
  formData.append('idmunicipality',municipalityId)
 try{

return  await axios.post('https://mqmc.org/cemse_service/schools.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const updateTarget = async(targetId,userId)=>{
  let formData = new FormData()
  formData.append('target_id',targetId)
  formData.append('user_id',userId)
 try{

return  await axios.post(' https://www.mqmc.org/cemse_service/update_target.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}

export const sendEmail = async(email)=>{
  let formData = new FormData()
  formData.append('email',email)
 try{

return  await axios.post('https://mqmc.org/cemse_service/send_email.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}
export const savePassword = async(code,password)=>{
  let formData = new FormData()
  formData.append('code',code)
  formData.append('password',password)
 try{

return  await axios.post('https://mqmc.org/cemse_service/restore_password.php',formData,
  { headers: { Accept: "application/json" } }
)
.then((response) => {
  return response.data;
})
  }catch(error){
    let errorCaught={
      error:'error',
      type:'Something went wrong',
      message:'Ha ocurrido un error intente nuevamente'
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      
     errorCaught.type='server failed'
      return errorCaught
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
    
      errorCaught.type='server not responding'
      return errorCaught
    } else {
      // Something happened in setting up the request that triggered an Errorconsole.log
     
      errorCaught.type='request triggered an error'
      return errorCaught
    }
    
}
}