import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import React from 'react'

const TermsModalComponent = ({open,handleClose}) => {
  return (
    <Dialog open={open}
    onClose={handleClose}>
        <DialogTitle>
        <Typography  className='terms__title'>Plataforma Virtual del programa “Me Quiero Me Cuido”</Typography>
          <Typography  className='terms__title' sx={{fontSize:'12px !important'}}>“Plataforma Virtual del programa “Me Quiero Me Cuido”</Typography>
        </DialogTitle>
        <DialogContent>
        <Typography className='terms'>
          El propósito de ChildFund International es mejorar la vida de las niñas, niños, adolescentes (NNA), para ello hemos desarrollado una plataforma virtual en las que las niñas, niños, adolescentes, jóvenes, padres, madres, cuidadores, docentes y líderes comunitarios podrán participar y capacitarse con el programa “Me quiero Me Cuido” en temas de:  sexualidad y salud integral, promoción de la igualdad de género, vínculos afectivos y reducción de riesgos y desastres.
          </Typography>
          <Typography className='terms'>
          El compromiso que tenemos con la protección y la salvaguarda de la niñez y adolescencia nos obliga a garantizar que todas nuestras intervenciones sean seguras, por ese motivo, esta plataforma tiene una serie de medidas de seguridad para evitar poner en riesgo a la niñez y adolescencia. Estas son algunas de las medidas que hemos tomado para garantizar la seguridad en esta plataforma. 
          </Typography>
          <Typography className='terms' sx={{textAlign:'left'}}><strong>Seguridad de la plataforma</strong></Typography>
          <Typography className='terms'>La plataforma presenta las siguientes medidas de seguridad para mantener la información confidencial:</Typography>
          <ul className='terms__ul'>
            <li>La plataforma cuenta con un certificado “SSL” que le permite establecer una conexión segura entre el usuario y la plataforma, para que nadie pueda interceptar la información compartida.</li>
            <li>La base de datos está resguardada evitando que terceras personas ajenas al servidor puedan tener acceso a la información.</li>
            <li>Toda la información confidencial como datos personales, se encuentra encriptada y no es posible leerla o interpretarla por otra persona.</li>
            <li>La plataforma utiliza la última versión de “recaptcha” de Google, para evitar que personas o bot maliciosos puedan interactuar con el login o foro de la plataforma. </li>
            <li>Se realizará una copia de respaldo de los datos de la plataforma y usuarios semanalmente.</li>
          </ul>
          <Typography className='terms'><strong>Salvaguarda Infantil</strong></Typography>
          <Typography className='terms'>Todas nuestras organizaciones socias de Childfund cuentan con una serie de políticas que nos ayudan a cumplir con nuestro compromiso de salvaguarda. </Typography>
          <Typography className='terms'>Asimismo, todas nuestras organizaciones socias se adhieren a la Política de Salvaguarda de ChildFund y la legislación nacional e internacional de protección de la niñez.</Typography>
          <Typography className='terms'>Es importante mencionar que además de mantener la confidencialidad de la información de las niñas y niños, las consideraciones sobre su seguridad son una prioridad para nosotros, por lo cual, mantenemos procedimientos de salvaguarda infantil. Si quiere conocer más sobre nuestras políticas de salvaguarda infantil puede revisarlas <a href='https://drive.google.com/file/d/1UdbGaoq--gSzzIfpDbub1RTV0HVU54Sn/view?usp=sharing' target='_blank'>aquí</a>.</Typography>
          <Typography className='terms'><strong>Sobre el formulario de registro</strong></Typography>
          <Typography className='terms'>La información recopilada en el formulario de registro solo se utilizará para realizar el seguimiento de los y las participantes por parte de ChildFund y las organizaciones socias: Cemse, Estrella del Sur, Mosoj Kausay, Esperanza Bolivia. Quienes mantendrán la información confidencial de una manera que sirva al bienestar y la seguridad de las niñas, niños, adolescentes y adultos. </Typography>
          <Typography  className='terms__title'>Participación de mi hija/hijo en actividades de la organización 
y manejo su información 
</Typography>
<Typography className='terms'>Por medio de la presente, declaro que conozco y entiendo el propósito de las actividades en las cuales participa mi hija o hijo, a través del curso virtual del programa “Me quiero Me Cuido” en temas de: sexualidad y salud integral, promoción de la igualdad de género, vínculos afectivos y reducción de riesgos y desastres, que Childfund y sus organizaciones socias ejecutan en la comunidad. Asimismo, estoy consciente que la participación de mi hijo o hija en el curso virtual favorecerá a su desarrollo en sus habilidades para la vida.</Typography>
          <Typography className='terms'>También he sido informado acerca de los datos recopilados en el siguiente formulario de registro se utilizará para realizar el seguimiento de los y las participantes por parte de ChildFund y las organizaciones socias: Cemse, Estrella del Sur, Mosoj Kausay, Esperanza Bolivia, Sociedad San José. Quienes mantendrán la información confidencial de una manera que sirva al bienestar y la seguridad de las niñas, niños y adolescentes.</Typography>
          <Typography className='terms'>Doy autorización para su participación</Typography>
          <Typography  className='terms__title'>Plataforma Virtual del programa “Me Quiero Me Cuido”</Typography>
          <Typography  className='terms__title' sx={{fontSize:'12px !important'}}>Madres, padres, cuidadores , docentes y líderes comunitarios (personas mayores de 18 años) </Typography>
          <Typography className='terms'>
          El propósito de ChildFund International es mejorar la vida de las niñas, niños, adolescentes (NNA), para ello hemos desarrollado una plataforma virtual en las que las niñas, niños, adolescentes, jóvenes, padres, madres, cuidadores, docentes y líderes comunitarios podrán participar y capacitarse con el programa “Me quiero Me Cuido” en temas de:  sexualidad y salud integral, promoción de la igualdad de género, vínculos afectivos y reducción de riesgos y desastres.
          </Typography>
          <Typography className='terms'>
          El compromiso que tenemos con la protección y la salvaguarda de la niñez y adolescencia nos obliga a garantizar que todas nuestras intervenciones sean seguras, por ese motivo, esta plataforma tiene una serie de medidas de seguridad para evitar poner en riesgo a la niñez y adolescencia. Estas son algunas de las medidas que hemos tomado para garantizar la seguridad en esta plataforma. 
          </Typography>
          <Typography className='terms'><strong>Seguridad de la plataforma</strong></Typography>
          <Typography className='terms'>La plataforma presenta las siguientes medidas de seguridad para mantener la información confidencial:</Typography>
          
          <ul className='terms__ul'>
            <li >La plataforma cuenta con un certificado “SSL” que le permite establecer una conexión segura entre el usuario y la plataforma, para que nadie pueda interceptar la información compartida.</li>
            <li>La base de datos está resguardada evitando que terceras personas ajenas al servidor puedan tener acceso a la información.</li>
            <li>Toda la información confidencial como datos personales, se encuentra encriptada y no es posible leerla o interpretarla por otra persona.</li>
            <li>La plataforma utiliza la última versión de “recaptcha” de Google, para evitar que personas o bot maliciosos puedan interactuar con el login o foro de la plataforma. </li>
            <li>Se realizará una copia de respaldo de los datos de la plataforma y usuarios semanalmente.</li>
          </ul>
          <Typography className='terms'><strong>Salvaguarda Infantil</strong></Typography>
          <Typography className='terms'>Todas nuestras organizaciones socias de Childfund cuentan con una serie de políticas que nos ayudan a cumplir con nuestro compromiso de salvaguarda. </Typography>
          <Typography className='terms'>Asimismo, todas nuestras organizaciones socias se adhieren a la Política de Salvaguarda de ChildFund y la legislación nacional e internacional de protección de la niñez.</Typography>
          <Typography className='terms'>Es importante mencionar que además de mantener la confidencialidad de la información de las niñas y niños, las consideraciones sobre su seguridad son una prioridad para nosotros, por lo cual, mantenemos procedimientos de salvaguarda infantil. Si quiere conocer más sobre nuestras políticas de salvaguarda infantil puede revisarlas <a href='https://drive.google.com/file/d/1UdbGaoq--gSzzIfpDbub1RTV0HVU54Sn/view?usp=sharing' target='_blank'>aquí</a>.</Typography>
          <Typography className='terms'><strong>Sobre el formulario de registro</strong></Typography>
          <Typography className='terms'>La información recopilada en el formulario de registro solo se utilizará para realizar el seguimiento de los y las participantes por parte de ChildFund y las organizaciones socias: Cemse, Estrella del Sur, Mosoj Kausay, Esperanza Bolivia. Quienes mantendrán la información confidencial de una manera que sirva al bienestar y la seguridad de las niñas, niños, adolescentes y adultos. </Typography>
          <Typography className='terms'>Estoy de acuerdo</Typography>

        </DialogContent>
        <DialogActions>
        <Button variant='contained'
              sx={{
                width: "216px",
                height: "46px",
                backgroundColor: "#12C185",
                alignSelf:'center',
                
                textTransform: 'none',
             
                borderRadius: "36px",
                fontWeight: "900",
                fontSize: "16px",
                lineHeight: "24px",
                "&:hover": {
                  background: green[300],
                },
            
       
          }} onClick={handleClose}>Entiendo, continuar</Button>
        </DialogActions>
    </Dialog>
  )
}

export default TermsModalComponent